import { Box, Button, Card, Center, H2, HStack, Icon, P1, P2, Switch, useConfig } from "@mailbrew/uikit";
import { usePaywallState } from "components/PaywallStateProvider";
import SourcesBadges from "components/SourcesBadges";
import { useRouter } from "next/router";
import { useState } from "react";
import { useSelector } from "react-redux";
import { authUserSelector } from "reducers/authReducer";
import urls from "urls";

export default function SubstackPaywall({ loading, onSubscribeToPlan }) {
  const router = useRouter();

  const [selectedPlanId, setSelectedPlanId] = useState("yearly");
  const selectedPlan = plans.find((p) => p.id === selectedPlanId);
  const showPeriodicitySwitch = "monthly" in router.query;
  const { highlightedFeature } = usePaywallState();
  const user = useSelector(authUserSelector);

  const handleCTAClick = () => {
    if (user) {
      onSubscribeToPlan(selectedPlan.productId, selectedPlan.periodicity);
    } else {
      router.push(urls.login(window.location.href));
    }
  };

  const handleTogglePeriodicity = () => {
    if (selectedPlanId === "yearly") {
      setSelectedPlanId("monthly");
    } else {
      setSelectedPlanId("yearly");
    }
  };

  return (
    <Card inline width="440px" maxW="100%">
      <Price plan={selectedPlan} />
      {showPeriodicitySwitch && (
        <PeriodicitySwitch yearly={selectedPlanId === "yearly"} onTogglePeriodicity={handleTogglePeriodicity} />
      )}
      <Center my={4}>
        <Box>
          {features.map((feature) => (
            <Feature
              key={feature.id}
              feature={feature}
              sourceTypes={feature.sourceTypes}
              isHighlighted={highlightedFeature === feature.feat_id}
              active
            />
          ))}
        </Box>
      </Center>
      <Center mt={4}>
        <Button onClick={handleCTAClick} loading={loading}>
          Subscribe now
        </Button>
      </Center>
    </Card>
  );
}

const percentOff = (price, discount) => (price * (1 - discount / 100)).toFixed(2);

const Price = ({ plan }) => {
  const { coupon } = usePaywallState();

  const monthlyPrice = (() => {
    if (plan.periodicity === "year") return plan.price / 12;
    else return plan.price;
  })();

  const discountedMonthlyPrice = coupon ? percentOff(monthlyPrice, coupon.percent_off) : monthlyPrice;
  const discountedPlanPrice = coupon ? percentOff(plan.price, coupon.percent_off) : plan.price;

  return (
    <Center>
      <H2>
        {coupon && <StrikedText>${monthlyPrice}</StrikedText>} ${discountedMonthlyPrice}/month
      </H2>
      {plan.periodicity === "year" && <P2>Billed yearly at ${discountedPlanPrice}</P2>}
      {plan.periodicity === "month" && <P2>Billed monthly</P2>}
    </Center>
  );
};

const PeriodicitySwitch = ({ yearly, onTogglePeriodicity }) => {
  return (
    <Center mt={2}>
      <HStack>
        <Switch small on={yearly} onClick={onTogglePeriodicity} />
        <P2>Yearly billing</P2>
      </HStack>
    </Center>
  );
};

const StrikedText = ({ children }) => {
  const config = useConfig();
  return <span style={{ textDecoration: "line-through", color: config.colors.c4 }}>{children}</span>;
};

const Feature = ({ feature, active, sourceTypes, isHighlighted }) => {
  const config = useConfig();

  return (
    <HStack mb={1} noWrap>
      <Icon size={16} name={active ? feature.icon : "close"} color={active ? config.colors.c3 : config.colors.c5} />
      <P1 color={active ? config.colors.c2 : config.colors.c5} fontWeight={isHighlighted && "bold"}>
        {feature.copy}
      </P1>
      <SourcesBadges size={20} sourceTypes={sourceTypes} style={{ opacity: active ? 1 : 0.4 }} />
    </HStack>
  );
};

const plans = [
  {
    id: "monthly",
    productId: "brewer",
    periodicity: "month",
    price: 4.99,
    features: [1, 2, 3, 4],
  },
  {
    id: "yearly",
    productId: "brewer",
    periodicity: "year",
    price: 59.88,
    features: [1, 2, 3, 4],
  },
];

const features = [
  { id: 1, copy: "Unlimited brews and sources", icon: "coffee" },
  { id: 3, copy: "Powerful newsletters reader", icon: "emailBold" },
  { id: 2, copy: "Your favorite content in one place", icon: "leaf" },
  { id: 4, copy: "Support a small indie team", icon: "heartBold" },
];
