import { Card, Icon, Stack, useBreakpoint, useConfig, useDebounce, useDetectBrowser } from "@mailbrew/uikit";
import React, { useEffect, useRef, useState } from "react";
import { Arrow, useLayer } from "react-laag";
import createTwitterLink from "utils/createTwitterLink";

export default function ShareSelectionOverlay({ children, url }) {
  const config = useConfig();

  const { selectedText, selectedRange, ref } = useTextSelection();
  const debouncedSelectedText = useDebounce(selectedText, 300);

  const showShareMenu = isTextSharable(debouncedSelectedText);

  const hit = useBreakpoint();

  const { renderLayer, layerProps, arrowProps } = useLayer({
    isOpen: showShareMenu,
    auto: true,
    placement: "bottom-center",
    triggerOffset: 10,
    trigger: {
      // The trigger is the element from which the overlay is showing from (used for centering the overlay)
      getBounds: () => selectedRange?.getBoundingClientRect() ?? {},
    },
  });

  const twitterUrl = (() => {
    let tweetText = debouncedSelectedText.trim();

    const maxTweetLength = 220; // It accommodates the link and some extra buffer

    if (tweetText.length > maxTweetLength) {
      tweetText = tweetText.substring(0, maxTweetLength) + "...";
    }

    const text = `“${tweetText}” (via @mailbrew)`;

    return createTwitterLink({ text, url });
  })();

  const facebookUrl = (() => {
    const text = encodeURIComponent(`“${debouncedSelectedText.trim()}” (via Mailbrew)`);

    return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&quote=${text}`;
  })();

  const linkedinUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}`;

  const iconSize = hit ? 22 : 20;
  const px = hit ? 4 : 3;
  const py = hit ? 3 : 2.5;
  const gap = hit ? 5 : 3.5;

  return (
    <>
      <div ref={ref}>{children}</div>
      {showShareMenu &&
        renderLayer(
          <div {...layerProps}>
            <Card border="none" bg={config.colors.c2} inline px={px} py={py}>
              <Stack gap={gap}>
                <MobileLink url={twitterUrl}>
                  <Icon name="twitter" color={config.colors.c5} hoverColor={config.colors.c6} size={iconSize} />
                </MobileLink>
                <MobileLink url={facebookUrl}>
                  <Icon name="facebook" color={config.colors.c5} hoverColor={config.colors.c6} size={iconSize} />
                </MobileLink>
                <MobileLink url={linkedinUrl}>
                  <Icon name="linkedin" color={config.colors.c5} hoverColor={config.colors.c6} size={iconSize} />
                </MobileLink>
              </Stack>
            </Card>
            <Arrow backgroundColor={config.colors.c2} roundness={2} size={8} {...arrowProps} />
          </div>
        )}
    </>
  );
}

const MobileLink = ({ url, children }) => {
  const { hasTouch: isMobile } = useDetectBrowser();
  const handleShare = () => {
    window.open(
      url,
      "_blank",
      "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=800,height=600,left=100,top=100"
    );
  };
  if (isMobile) {
    return (
      <a
        style={{
          background: "rgba(0,0,0,0.01)",
          lineHeight: 0,
          padding: "6px",
          margin: "-6px",
        }}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    );
  } else {
    return (
      <div style={{ cursor: "pointer" }} onClick={handleShare}>
        {children}
      </div>
    );
  }
};

function isTextSharable(text) {
  if (!text || text.length === 0) {
    return false;
  }

  return text.split(" ").length >= 3;
}

function useTextSelection() {
  // we need a reference to the element wrapping the text in order to determine
  // if the selection is the selection we are after
  const ref = useRef();
  const [selectedRange, setSelectedRange] = useState(null);
  const [selectedText, setSelectedText] = useState("");

  useEffect(() => {
    function handleChange() {
      // get selection information from the browser
      const selection = window.getSelection();

      // proceed only when we have a valid selection
      if (!selection || selection.isCollapsed || !selection.containsNode(ref.current, true)) {
        setSelectedRange(null);
        setSelectedText("");
        return;
      }

      setSelectedRange(selection.getRangeAt(0));
      setSelectedText(selection.toString());
    }

    document.addEventListener("selectionchange", handleChange);
    return () => document.removeEventListener("selectionchange", handleChange);
  }, []);

  return { ref, selectedRange, selectedText };
}
