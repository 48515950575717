/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { Button, Icon, notif, Spinner, useConfig } from "@mailbrew/uikit";
import axios from "axios";
import api from "dependencies/api";
import { AnimatePresence, motion } from "framer-motion";
import { Fragment, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authUserSelector, updateMe } from "reducers/authReducer";
import { CurrentUserAvatarImage } from "./UserAvatarImage";

const imageSizeLimitMB = 2;
const supportedFormats = ["image/png", "image/jpg", "image/jpeg", "image/gif"];

const ProfileImageWithUpload = ({ size = 100 }) => {
  const dispatch = useDispatch();
  const user = useSelector(authUserSelector);

  const config = useConfig();

  const [uploadMode, setUploadMode] = useState(false);

  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [selectedImageBase64, setSelectedImageBase64] = useState(null);
  const fileInputRet = useRef();

  function reset() {
    setUploadMode(false);
    setLoading(false);
    setFile(null);
    setSelectedImageBase64(null);
  }

  const handleImageClick = () => {
    if (loading) return;
    fileInputRet.current.click(); // show file picker
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const fileSizeMB = file.size / 1024 / 1024;

    if (fileSizeMB > imageSizeLimitMB) {
      notif.error("This image is too big. Max size: 2MB.");
      return;
    }

    if (!supportedFormats.includes(file.type)) {
      notif.error("Unsupported file format. Please use PNG, JPEG or GIF.");
      return;
    }

    setFile(file);

    const reader = new FileReader();
    reader.onload = (e) => setSelectedImageBase64(e.target.result);
    reader.readAsDataURL(file);

    setUploadMode(true);
  };

  const handleFileFormSubmit = async () => {
    setLoading(true);

    let res;

    // get signed upload url
    try {
      res = await api.post("/start_profile_image_upload/", { file_type: file.type });
    } catch (err) {
      notif.error(err?.response?.data?.detail || "Error uploading image");
      setUploadMode(false);
      return;
    }

    // S3 upload
    try {
      await axios.put(res.data.upload_url, file, { headers: { "Content-Type": file.type } });
    } catch (err) {
      notif.error("Error uploading image to S3. Please try again.");
      setUploadMode(false);
      return;
    }

    // update profile
    try {
      await api.put(`/profile/${user.id}/`, { image_url: res.data.image_url });
    } catch (err) {
      notif.error(err?.response?.data?.detail || "Error updating your profile with the new image.");
      setUploadMode(false);
      return;
    }

    // update me
    await dispatch(updateMe());

    notif.success("Avatar changed");

    reset();
  };

  return (
    <Fragment>
      <div
        style={{
          position: "relative",
          width: size,
          height: size,
        }}
      >
        <div
          onClick={(e) => {
            if (!uploadMode) {
              handleImageClick(e);
            }
            if (uploadMode && !loading) {
              reset();
            }
          }}
          css={css`
            width: ${size}px;
            height: ${size}px;
            border-radius: 50%;
            overflow: hidden;
            position: relative;
            cursor: pointer;
            border: 1px solid ${config.colors.c6};
            .overlay {
              font-size: 13px;
              font-weight: 500;
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              background: rgba(0, 0, 0, 0.3);
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              box-sizing: border-box;
              padding: 2px;
              text-align: center;
              color: white;
              z-index: 2;
            }
            ${!(uploadMode && loading)
              ? css`
                  :not(:hover) {
                    > .overlay {
                      display: none;
                    }
                  }
                `
              : ""}
          `}
        >
          {!uploadMode && (
            <div className="overlay">
              <Icon name="writeBold" color="white" size="14px" />
            </div>
          )}
          {uploadMode && !loading && (
            <div className="overlay">
              <Icon name="close" color="white" size="14px" />
            </div>
          )}
          {uploadMode && loading && (
            <div className="overlay">
              <Spinner color="white" size={14} />
            </div>
          )}
          <CurrentUserAvatarImage srcOverride={selectedImageBase64} />
        </div>
        <AnimatePresence>
          {uploadMode && !loading && (
            <motion.div
              initial={{ scale: 0.5, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.5, opacity: 0 }}
              style={{
                position: "absolute",
                left: 2,
                bottom: -15,
                right: 0,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                zIndex: 3,
              }}
            >
              <Button
                noStretch
                color={config.colors.c2}
                variant={["pill"]}
                icon="uploadCloud"
                onClick={handleFileFormSubmit}
              >
                Upload
              </Button>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <form onSubmit={handleFileFormSubmit}>
        <input type="file" ref={fileInputRet} onChange={handleFileChange} style={{ display: "none" }} />
      </form>
    </Fragment>
  );
};

export default ProfileImageWithUpload;
