export default function domainFromURL(url) {
  if (!url || url.length === 0) {
    return "";
  }
  let hostname = "";
  if (url.indexOf("//") > -1) {
    hostname = url.split("/")[2];
  } else {
    hostname = url.split("/")[0];
  }
  if (hostname.includes("www")) {
    return hostname.replace("www.", "");
  } else {
    return hostname;
  }
}
