const { useConfig, Box } = require("@mailbrew/uikit");

const ReadingProgressIndicator = ({ progress }) => {
  if (!progress) {
    return (
      <ProgressIndicatorWrapper>
        <ProgressCircle />
      </ProgressIndicatorWrapper>
    );
  }

  return null;
};

const ProgressIndicatorWrapper = ({ children }) => {
  return <div style={{ position: "absolute", top: 14, right: 14 }}>{children}</div>;
};

const ProgressCircle = (props) => {
  const config = useConfig();
  return (
    <div style={{ padding: "4px" }}>
      <Box
        w="10px"
        h="10px"
        radius="10px"
        color={config.colors.accent2}
        background={config.colors.accent2}
        shadow={`inset 0px 0px 0px 2px ${config.colors.accent2}`}
        {...props}
      />
    </div>
  );
};

export default ReadingProgressIndicator;
