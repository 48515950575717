import { Box, CircleButton, SafeAreaInsetBottom, useBreakpoint, useConfig, useLockBodyScroll } from "@mailbrew/uikit";
import { AnimatePresence, motion } from "framer-motion";
import React, { forwardRef } from "react";
import ReactDOM from "react-dom";

const FullScreenModal = forwardRef(({ show, setShow, zIndex = 1, children }, ref) => {
  const config = useConfig();
  const hit = useBreakpoint();

  useLockBodyScroll(show);

  return ReactDOM.createPortal(
    <AnimatePresence>
      {show && (
        <Box
          key="modal"
          as={motion.div}
          bg={config.colors.bg0}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: parseInt(zIndex) + 1,
            overflow: "scroll",
            display: "flex",
            flexDirection: "column",
          }}
          initial={{
            y: "100%",
          }}
          animate={{
            y: "0%",
          }}
          exit={{
            y: "100%",
          }}
          transition={{ type: "spring", duration: 0.6, bounce: 0.05 }}
          ref={ref}
        >
          {ReactDOM.createPortal(
            show && (
              <motion.div
                style={{
                  position: "fixed",
                  top: hit ? 5 : 10,
                  right: hit ? 5 : 10,
                  zIndex: parseInt(zIndex) + 2,
                }}
                initial={{ y: "100vh", opacity: 0 }}
                animate={{ y: "0vh", opacity: 1 }}
                exit={{ y: "100vh", opacity: 0 }}
                transition={{ type: "spring", duration: 0.6, bounce: 0.05 }}
              >
                <CircleButton
                  size={28}
                  iconSize={20}
                  iconStroke={2}
                  icon={"close"}
                  color={config.colors.c4}
                  shadow="none"
                  onClick={() => setShow(false)}
                />
              </motion.div>
            ),
            document.body
          )}
          {children}
          <SafeAreaInsetBottom />
        </Box>
      )}
      {/* backdrop */}
      {show && (
        <Box
          key="backdrop"
          as={motion.div}
          bg={config.Modal.background}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: parseInt(zIndex),
          }}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          exit={{
            opacity: 0,
          }}
          transition={{ type: "spring", duration: 0.4, bounce: 0.05 }}
        />
      )}
    </AnimatePresence>,
    document.body
  );
});

export default FullScreenModal;
