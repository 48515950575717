/* eslint-disable jsx-a11y/alt-text */
import { Box, useConfig } from "@mailbrew/uikit";
import { motion } from "framer-motion";
import { useState } from "react";

const FadeInImage = (props) => {
  const config = useConfig();
  const [loaded, setLoaded] = useState(false);

  return (
    <Box
      as={motion.img}
      onLoad={() => setLoaded(true)}
      initial={{ opacity: 0 }}
      animate={loaded ? { opacity: 1 } : { opacity: 0 }}
      transition={{ type: "spring", ...config.springs.medium }}
      maxW="100%"
      {...props}
    />
  );
};

export default FadeInImage;
