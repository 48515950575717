import { Modal } from "@mailbrew/uikit";
import { useRouter } from "next/router";
import { useEffect } from "react";
import urls from "urls";
import Paywall from "./Paywall";
import { usePaywallState } from "./PaywallStateProvider";

export default function PaywallModal({ show, setShow }) {
  const router = useRouter();

  const { setHighlightedFeature, monetizationStatus } = usePaywallState();

  // automatically show on success so we can handle the completion from everywhere
  useEffect(() => {
    const checkoutSessionID = router.query.checkout_id;
    if (checkoutSessionID && router.pathname !== urls.upgrade()) {
      setShow(true);
    }
  }, [router.pathname, router.query.checkout_id, setShow]);

  const handleSetShow = (newValue) => {
    // reset when closing paywall
    if (newValue === false) setHighlightedFeature(null);

    setShow(newValue);
  };

  const isBottomSheet = monetizationStatus !== "subscribed";

  return (
    <Modal bottomSheet={isBottomSheet} bottomSheetWidth="850px" pb={6} show={show} setShow={handleSetShow}>
      <Paywall inModal />
    </Modal>
  );
}
