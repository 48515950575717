import { Button } from "@mailbrew/uikit";

const StyledA = ({ id, href, type = "link", aStyle, targetBlank, stretch, ...rest }) => {
  const targetBlankProps = targetBlank ? { target: "_blank", rel: "noreferrer noopener" } : {};

  return (
    <a
      id={id}
      href={href}
      {...targetBlankProps}
      style={{ width: stretch ? "100%" : "auto", display: "inline-flex", ...aStyle }}
    >
      <Button as="div" type={type} style={{ flex: 1 }} stretch={stretch} {...rest} />
    </a>
  );
};

export default StyledA;
