import { excludedPaths, excludedTags } from "components/KeyboardShortcutsManager";
import { useSelector } from "react-redux";
import { authUserSelector } from "reducers/authReducer";
import useEventListener from "./useEventListener";

export default function useKeyPressed(key, callback, options) {
  const user = useSelector(authUserSelector);

  const { withShift } = options || {};

  useEventListener("keyup", (e) => {
    if (!user) {
      return;
    }
    if (excludedTags.includes(e.target?.tagName)) {
      return;
    }
    if (excludedPaths.find((path) => window.location.pathname.includes(path))) {
      return;
    }
    handleKeyPress(e);
  });

  function handleKeyPress(e) {
    if (e.key === key && (withShift === true ? e.shiftKey : true)) {
      callback && callback();
    }
  }

  return null;
}
