import { Box, CircleButton, P1, Spacer, useConfig, useDetectBrowser } from "@mailbrew/uikit";
import { motion } from "framer-motion";
import FullScreenModal from "FullScreenModal";
import useHandleShareUrl from "hooks/useHandleShareUrl";
import useIsAtTopOrBottomOfScroll from "hooks/useIsAtTopOrBottomOfScroll";
import Head from "next/head";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import createTwitterLink from "utils/createTwitterLink";
import fetchNewsletterShareLink from "utils/fetchNewsletterShareLink";
import zIndexes from "../zIndexes";
import InboxMessageView from "./InboxMessageView";
import {
  ModalToolbar,
  ToolbarArchiveButton,
  ToolbarCloseButton,
  ToolbarReadLaterButton,
  ToolbarShareButton,
  ToolbarTweetButton,
} from "./Toolbars";

const InboxMessageModal = ({
  id,
  show,
  setShow,
  src,
  hideReadLater,
  withArchive,
  onArchiveClick,
  prevId,
  nextId,
  onPrevClick,
  onNextClick,
}) => {
  const { isPwa } = useDetectBrowser();

  const [currentId, setCurrentId] = useState(id);

  useEffect(() => {
    setCurrentId(id);
  }, [id]);

  const [error, setError] = useState(false);

  const handleError = useCallback(() => {
    setError(true);
  }, []);

  const modalRef = useRef();

  const [toolbarShown, setToolbarShown] = useState(show);
  useEffect(() => {
    if (show) {
      setToolbarShown(show);
    }
  }, [show]);

  const [toolbarVisible, setToolbarVisible] = useIsAtTopOrBottomOfScroll({
    scrollElement: modalRef.current,
    offset: 250,
  });

  useEffect(() => {
    setToolbarVisible(show);
  }, [setToolbarVisible, show]);

  const handleSetShow = (show) => {
    // Toolbar need to be hidden before the entire modal is closed,
    // Otherwise its exit animation breaks
    if (!show) {
      setToolbarShown(false);
    }
    setTimeout(() => {
      setShow(show);
    });
  };

  useEffect(() => {
    if (!show) {
      setError(false);
    }
  }, [show]);

  const handleShareUrl = useHandleShareUrl();

  return (
    <FullScreenModal show={show} setShow={handleSetShow} ref={modalRef} zIndex={zIndexes.inboxMessageModal}>
      <Spacer size={8} />
      {error && (
        <Box style={{ flex: 1 }} flex fd="column" ai="center" jc="center">
          <P1 mb={3}>Sorry, we couldn't fetch the newsletter 😔</P1>
        </Box>
      )}
      <InboxMessageView
        fullTitle
        messageId={currentId}
        src={src ?? "inbox-message-modal"}
        onMessageError={handleError}
        modalRef={modalRef}
        subjectLinksToMessage
        externalChildren={(message) => {
          function handleShareClick() {
            fetchNewsletterShareLink(message.message_id, handleShareUrl);
          }

          function handleTweetClick() {
            fetchNewsletterShareLink(message.message_id, (url) =>
              window.open(createTwitterLink({ text: `${message.subject} (via @mailbrew)`, url }), "_blank")
            );
          }

          return (
            <Fragment>
              {message?.subject && message?.sender_name && (
                <Head>
                  <title>{(message.subject + " - " + message.sender_name).toString()}</title>
                </Head>
              )}
              <ModalToolbar show={toolbarVisible && toolbarShown}>
                <NextPrevButton show={prevId} onClick={onPrevClick} isNext={false} />
                <ToolbarShareButton onClick={handleShareClick} />
                {!isPwa && <ToolbarTweetButton onClick={handleTweetClick} />}
                {!hideReadLater && <ToolbarReadLaterButton url={message?.read_url} />}
                {withArchive && onArchiveClick && (
                  <ToolbarArchiveButton
                    onClick={() => {
                      onArchiveClick();
                      setShow(false);
                    }}
                  />
                )}
                <ToolbarCloseButton onClick={() => handleSetShow(false)} />
                <NextPrevButton show={nextId} onClick={onNextClick} isNext={true} />
              </ModalToolbar>
            </Fragment>
          );
        }}
      />
    </FullScreenModal>
  );
};

const NextPrevButton = ({ show, onClick, isNext }) => {
  const config = useConfig();

  return (
    <motion.div
      initial={false}
      transition={{ duration: 0.2, bounce: 0.1 }}
      animate={{
        width: show ? "auto" : 0,
        opacity: show ? 1 : 0,
        ...(isNext ? { marginLeft: show ? "0px" : "-18px" } : { marginRight: show ? "0px" : "-18px" }),
      }}
    >
      <CircleButton
        size={30}
        iconSize={22}
        iconStroke={2}
        icon={isNext ? "chevronRight" : "chevronLeft"}
        variant="secondary"
        color={config.colors.c2}
        onClick={() => onClick && onClick()}
        mr={isNext ? 0 : 1}
        ml={isNext ? 1 : 0}
      />
    </motion.div>
  );
};

export default InboxMessageModal;
