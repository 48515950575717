/* eslint-disable jsx-a11y/alt-text */
import { Box, useConfig } from "@mailbrew/uikit";
import { useMemo } from "react";

export default function InlineCardPreviewImage({
  previewUrl,
  previewSize = 800,
  imageUrl,
  padding,
  mb,
  onClick,
  aspectRatio = 0.4,
  backgroundColor = "hsl(30, 6%, 96%)",
  style,
  children: providedChildren,
}) {
  const config = useConfig();

  const backgroundSrc = useMemo(() => imageUrl ?? getImagePreviewFromUrl(previewUrl, previewSize), [
    imageUrl,
    previewSize,
    previewUrl,
  ]);

  const absPosition = {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  };

  const ImageWrapper = ({ children, ...otherProps }) => (
    <Box
      w={`calc( 100% + ${padding || config.Card.smallPadding} + ${padding || config.Card.smallPadding} )`}
      mt={"-" + (padding || config.Card.smallPadding)}
      ml={"-" + (padding || config.Card.smallPadding)}
      h="0px"
      paddingBottom={aspectRatio * 100 + "%"}
      borderBottom={`1px solid ${config.colors.uiBorderColor}`}
      mb={mb}
      position="relative"
      overflow="hidden"
      style={{
        background: backgroundColor ?? config.colors.c6,
        ...style,
      }}
      onClick={onClick}
      {...otherProps}
    >
      <div
        style={{
          ...absPosition,
          background: "rgba(0,0,0,.02)",
          pointerEvents: "none",
          zIndex: 2,
        }}
      />
      {providedChildren && (
        <div
          style={{
            ...absPosition,
            pointerEvents: "none",
            zIndex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {providedChildren}
        </div>
      )}
      {children}
    </Box>
  );

  if (!previewUrl && !imageUrl) {
    return <ImageWrapper />;
  }

  return (
    <ImageWrapper
      style={{
        cursor: "pointer",
        ...style,
      }}
      alt={`Preview`}
    >
      <img
        src={backgroundSrc}
        style={{
          ...absPosition,
          width: "100%",
          height: "100%",
          objectFit: "cover",
          objectPosition: imageUrl ? "center" : "top center",
          imageRendering: "high-quality",
        }}
      />
    </ImageWrapper>
  );
}

export function getImagePreviewFromUrl(url, previewSize) {
  const exp1week = 3600 * 24 * 7;

  let params = `&exp=${exp1week}`;

  if (url?.includes("newsletters.mailbrew.com")) {
    params += "&axios=1";
  }

  const contentUrl = encodeURIComponent(url);
  return `https://share.mailbrew.com/screenshot?size=${previewSize}x900${params}&url=${contentUrl}`;
}
