import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authUserSelector, updateMe } from "reducers/authReducer";

export default function useUpdateUser() {
  const dispatch = useDispatch();
  const user = useSelector(authUserSelector);
  const loggedIn = !!user;

  useEffect(() => {
    if (loggedIn) dispatch(updateMe());
  }, [dispatch, loggedIn]);
}
