import { useEffect, useState } from "react";

/** Listens for dark mode changes and provides them to its children. */
export default function useDarkModeListener({ onChange } = {}) {
  const [isDark, setIsDark] = useState(
    () => typeof window !== "undefined" && window?.matchMedia("(prefers-color-scheme: dark)")?.matches
  );

  useEffect(() => {
    const handleDarkModeChange = (event) => {
      // only update the hook if dark mode changes
      // wrt originally detected value
      if (event.matches && !isDark) {
        setIsDark(true);
        onChange && onChange(true);
      } else if (!event.matches && isDark) {
        setIsDark(false);
        onChange && onChange(false);
      }
    };

    const mediaQuery = typeof window !== "undefined" && window?.matchMedia("(prefers-color-scheme: dark)");
    mediaQuery?.addEventListener && mediaQuery.addEventListener("change", handleDarkModeChange);
    return () => mediaQuery?.removeEventListener && mediaQuery.removeEventListener("change", handleDarkModeChange);
  }, [isDark, onChange]);

  return { isDark };
}
