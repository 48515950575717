import { Tooltip, useDarkMode } from "@mailbrew/uikit";

const StyledTooltip = (props) => {
  const [darkMode] = useDarkMode();

  if ((props.title && props.title.length) || props.html) {
    return (
      <Tooltip
        key={darkMode?.toString()}
        touchHold={props.touchHold}
        interactive={props.interactive}
        maxWidth={props.maxWidth}
        popperOptions={{
          modifiers: {
            preventOverflow: {
              boundariesElement: "window",
              enabled: true,
              padding: 20,
            },
          },
        }}
        {...props}
      />
    );
  } else {
    return props.children;
  }
};

export default StyledTooltip;
