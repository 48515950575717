export default function kFormatter(num, decimals = 1) {
  if (999 < num && num < 999999) {
    return (num / 1e3).toFixed(decimals) + "K";
  }

  if (num > 999999) {
    return (num / 1e6).toFixed(decimals) + "M";
  }

  return num;
}
