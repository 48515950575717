import { v4 as uuidv4 } from "uuid";
import { sourcesData } from "../data/sourcesData";

export const sourcesModalShortcuts = [
  {
    title: "Get a head start",
    icon: "lightningBold",
    content: [
      "today",
      "weather",
      "calendar",
      "inbox",
      {
        kind: "source",
        title: "Daily Quote",
        id: "quote",
        noInlineEditing: true,
        source: {
          id: uuidv4(),
          type: "subreddit",
          subreddit: "quotes",
          num_posts: 1,
          post_body: "full",
          quote_mode: true,
          style: "simple",
        },
      },
    ],
  },
  {
    title: "Get the best Twitter content",
    icon: "twitter",
    content: [
      "twitter_top_links",
      {
        kind: "flow",
        title: "Best Tweets",
        id: "twitter_digest",
        icon: "twitter_user",
        info: "Select your favorite follows and receive their best tweets",
      },
      "twitter_list",
      "twitter_timeline",
      "twitter_user",
    ],
  },
  {
    title: "Follow your interests",
    icon: "starBold",
    content: ["rss_feed", "subreddit", "youtube_playlist", "podcast", "readwise", "read_later"],
  },
  {
    title: "Tech & programming",
    icon: "laptop",
    content: ["hacker_news", "product_hunt", "betalist", "devto"],
  },
  {
    title: "Keep things monitored",
    icon: "growthBold",
    content: ["exchange_rate", "stocks", "crypto", "google_news", "covid"],
  },
  {
    title: "Decorate your brew",
    icon: "content",
    content: ["text", "separator"],
  },
  {
    title: "Advanced Twitter",
    icon: "twitter",
    content: ["twitter_likes", "twitter_search"],
  },
  {
    title: "From the Mailbrew team",
    icon: "mailbrew",
    content: [
      {
        kind: "source",
        title: "Changelog",
        id: "mailbrew_changelog",
        noInlineEditing: true,
        source: {
          id: uuidv4(),
          type: "rss_feed",
          title: "Mailbrew Updates",
          url: "https://changelog.mailbrew.com/rss",
          num_posts: 1,
          show_images: true,
          post_body: "excerpt",
        },
      },
    ],
  },
];

export function getSourceTitle(source) {
  if (!source) return "Source";

  const maxLength = 32;

  // ft_name are params only used in the frontend

  // prettier-ignore
  const title = (() => {
    if (source.type === "today") return "Date";
    if (source.type === "subreddit") return source.subreddit ? `${source.subreddit}` : "Subreddit";
    if (source.type === "twitter_user") return source.screen_name ? `@${source.screen_name}` : "Twitter User";
    if (source.type === "twitter_search") return source.title || "Twitter Search";
    if (source.type === "twitter_list") return source.title || "Twitter List";
    if (source.type === "twitter_timeline") return source.title || "Twitter Timeline";
    if (source.type === "twitter_top_links") return source.title || (source.ft_list_title ? "Top links from " + source.ft_list_title : null) || "Twitter Top Links";
    if (source.type === "twitter_likes") return source.title || (source.ft_list_title ? "Liked by " + source.ft_list_title : null) || "Twitter Likes";
    if (source.type === "rss_feed") return source.title || "RSS Feed";
    if (source.type === "youtube_playlist") return source.title || "YouTube";
    if (source.type === "youtube_channel") return source.title || "YouTube";
    if (source.type === "covid") return source.country_name ? `Covid-19 / ${source.country_name}` : "Covid-19";
    if (source.type === "hacker_news") return source.title.length ? source.title : "Hacker News";
    if (source.type === "google_news" && source.mode === "query") return source.title || source.query.length > 0 ? `"${source.query}" on Google News` : "Google News";
    if (source.type === "google_news" && source.mode === "topic") return source.title || `Google News`;
    if (source.type === "exchange_rate") return `Exchange Rate - ${source.base}`;
    if (source.type === "stocks") return source.title?.length > 0 ? source.title : "Stocks";
    if (source.type === "crypto") return source.title?.length > 0 ? source.title : "Crypto";
    if (source.type === "text") return source.text?.length > 0 ? clamp(source.text, maxLength) : "Text";
    if (source.type === "separator") return "Separator";
    if (source.type === "inbox") return source.title || "Newsletters";
    if (source.type === "product_hunt") return "Product Hunt";
    if (source.type === "betalist") return "BetaList";
    if (source.type === "devto") return `Dev.to` + (source.tag ? ` #${source.tag}` : "");
    if (source.type === "read_later") return "Saved";
    if (source.type === "readwise") return "Readwise";
    if (source.type === "calendar") return `Calendar`;
    if (source.type === "podcast") return `Podcast`;
    return sourcesData[source.type]?.name || "Source";
  })();

  return clamp(title, maxLength);
}

function clamp(text, maxLength) {
  return text.length > maxLength ? text.slice(0, maxLength - 3) + "..." : text;
}

// prettier-ignore
export function getSourceURL(source) {
  if (source.type === "subreddit") return `https://reddit.com/r/${source.subreddit}`;
  if (source.type === "twitter_user") return `https://twitter.com/${source.screen_name}`;
  if (source.type === "twitter_search") return `https://twitter.com/search?q=${encodeURIComponent(source.query)}`;
  if (source.type === "twitter_list") return `https://twitter.com/i/lists/${source.list_id}`;
  if (source.type === "youtube_channel") return source.channel_url;
  if (source.type === "google_news" && source.mode === "query") return `https://news.google.com/search?q=${source.query}`;
  if (source.type === "google_news" && source.mode === "topic") return source.url;
  if (source.type === "devto") return source.tag ? `https://dev.to/t/${source.tag}` : "https://dev.to/";
  if (source.type === "product_hunt") return "https://www.producthunt.com/";
  if (source.type === "betalist") return "https://betalist.com/";
  if (source.type === "readwise") return "https://readwise.io/";
  if (source.type === "calendar") return "https://calendar.google.com";
  return null;
}
