import { useSelector } from "react-redux";
import { authUserSelector } from "../reducers/authReducer";

const fallback_image_url = "https://mailbrew-assets.s3.us-east-1.amazonaws.com/webapp/user-placeholder.png";

/** Current User Avatar Image */
export const CurrentUserAvatarImage = ({ srcOverride, style }) => {
  const user = useSelector(authUserSelector);

  return (
    <img
      src={srcOverride || user?.profile.image_url || fallback_image_url}
      alt={user?.full_name}
      width="100%"
      height="100%"
      style={{ objectFit: "cover", ...style }}
    />
  );
};

/** Generic Avatar Image */
export const AvatarImage = ({ src, style }) => {
  return (
    <img src={src || fallback_image_url} alt={""} width="100%" height="100%" style={{ objectFit: "cover", ...style }} />
  );
};
