import { AutoGrid, useBreakpoint } from "@mailbrew/uikit";

export default function CardsAutoGrid({ children, ...otherProps }) {
  const [small, medium] = useBreakpoint([656, 976]);

  const cols3 = children.length % 3;
  const cols2 = children.length % 2;

  return (
    <AutoGrid gap={5} cellWidth={small ? "250px" : "300px"} {...otherProps}>
      {children}
      {!medium && (
        <>
          {cols3 === 1 && <div />}
          {cols3 > 0 && <div />}
        </>
      )}
      {!small && medium && <>{cols2 === 1 && <div />}</>}
    </AutoGrid>
  );
}
