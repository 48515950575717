import { useEffect, useState } from "react";

export default function useDelayedValue(value, delay, onlyDelayIf) {
  const [delayedValue, setDelayedValue] = useState(value);

  useEffect(() => {
    let timeout = setTimeout(
      () => {
        setDelayedValue(value);
      },
      onlyDelayIf ?? onlyDelayIf ? (value ? delay : 0) : value ? 0 : delay
    );
    return () => clearTimeout(timeout);
  }, [value, delay, onlyDelayIf]);

  return delayedValue;
}
