import Axios from "axios";
import api from "dependencies/api";
import notifApiError from "./notifApiError";

export default async function fetchNewsletterShareLink(messageId, callback) {
  try {
    const res = await api.post(`/inbox_source_messages/${messageId}/share/`);

    const shareURL = res.data.share_url;
    const publicMessage = res.data.message;

    // prefetch to warmup image cache
    Axios.get(publicMessage?.card_url).catch((err) => {});

    callback(shareURL);
  } catch (err) {
    notifApiError(err, "Can't share your message");
  }
}
