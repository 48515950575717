import { useTimeout } from "@mailbrew/uikit";
import { useState } from "react";

const usePwa = () => {
  const [canInstallPwa, setCanInstallPwa] = useState(false);
  const [pwaInstallPrompt, setPwaInstallPrompt] = useState(false);

  useTimeout(
    () => {
      setCanInstallPwa(window.showPwaInstallButton);
      setPwaInstallPrompt(window.pwaInstallPrompt);
    },
    1500,
    []
  );

  return { canInstallPwa, pwaInstallPrompt };
};

export default usePwa;
