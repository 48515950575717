import { Box, Expandable, Icon, P2, Stack, useConfig } from "@mailbrew/uikit";
import { motion } from "framer-motion";
import { useState } from "react";
import SourceEditorSection from "./editor/SourceEditorSection";

export default function PostItNote({ title, icon, children, width = "auto" }) {
  const config = useConfig();

  const [expanded, setExpanded] = useState(true);

  return (
    <Expandable expanded={expanded}>
      <SourceEditorSection noBorders>
        <Box
          as={motion.div}
          initial={{ opacity: 0, y: 40, rotate: 2 }}
          animate={{ opacity: 1, y: 0, rotate: 0.5 }}
          transition={{ type: "spring", duration: 0.55, bounce: 0.15, delay: 0.2 }}
          p={3}
          background={config.colors.lightOrange}
          border={`1px solid ${config.colors.lightOrangeBorder}`}
          borderRadius="8px"
          position="relative"
          w={"100%"}
          maxW={width}
        >
          {title && (
            <Stack gap={0}>
              {icon && <Icon name={icon} color={config.colors.c1} size={16} offset={1} mr={1} />}
              <P2 mb={0.5} color={config.colors.c1} bold>
                {title}
              </P2>
            </Stack>
          )}
          {children && <P2 color={config.colors.c2}>{children}</P2>}
          <Icon
            name="close"
            style={{ position: "absolute", top: 5, right: 5, opacity: 0.5 }}
            color={config.colors.c3}
            hoverColor={config.colors.c1}
            onClick={() => setExpanded(false)}
          />
        </Box>
      </SourceEditorSection>
    </Expandable>
  );
}
