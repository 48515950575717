import { useEventListener } from "@mailbrew/uikit";
import { useRef, useState } from "react";

export default function useIsAtTopOrBottomOfScroll({
  scrollElement,
  offset = 100,
  initialState = true,
  showOnScrollUp = true,
}) {
  const [isTopOrBottom, setIsTopOrBottom] = useState(initialState);

  const [scrollTop, setScrollTop] = useState(0);

  const isAtTop = useRef(initialState);
  const isAtBottom = useRef(false);
  const isScrollingUp = useRef(false);

  const prevScrollY = useRef(0);

  if (scrollTop === 0) {
    isAtTop.current = true;
    isScrollingUp.current = false;
  }

  useEventListener(
    "scroll",
    (e) => {
      let { scrollTop: targetScrollTop, clientHeight: windowHeight, scrollHeight: totalHeight } = e.target;

      if (!scrollElement && typeof window !== undefined) {
        setScrollTop(window.scrollY);
        windowHeight = window.innerHeight;
        totalHeight = document.documentElement.scrollHeight;
      } else {
        setScrollTop(targetScrollTop);
      }

      isAtTop.current = scrollTop <= offset;
      isAtBottom.current = scrollTop + windowHeight > totalHeight - offset;

      if (scrollTop - prevScrollY.current < -10) {
        isScrollingUp.current = true;
      }

      if (scrollTop - prevScrollY.current > 5 && !isAtBottom.current) {
        isScrollingUp.current = false;
      }

      if (showOnScrollUp) {
        setIsTopOrBottom(isAtTop.current || isAtBottom.current || isScrollingUp.current);
      } else {
        setIsTopOrBottom(isAtTop.current || isAtBottom.current);
      }

      prevScrollY.current = scrollTop;
    },
    scrollElement,
    { debounceDelay: 5 }
  );

  return [isTopOrBottom, setIsTopOrBottom, scrollTop];
}
