import api from "dependencies/api";
import debounce from "just-debounce-it";
import avatarFromUrl from "./avatarFromUrl";
import kFormatter from "./kFormatter";
import notifApiError from "./notifApiError";

export const searchStock = debounce(
  async (
    query,
    {
      setSuggestions,
      setSuggestionsNames,
      setSuggestionsDetails,
      setSuggestionsImages,
      setSuggestionsPayloads,
      setLoading,
      setError,
    }
  ) => {
    if (!query || query.length <= 2) {
      setSuggestions([]);
      setError(null);
      return;
    }

    setLoading(true);

    try {
      setError("");
      const res = await api.get("/search_stock/", {
        params: { q: query },
      });
      setSuggestions(res.data.map((s) => s.symbol));
      setSuggestionsNames(res.data.map((s) => s.symbol));
      setSuggestionsImages(res.data.map((s) => s.logo));
      setSuggestionsDetails(res.data.map((s) => s.security_name));
      setSuggestionsPayloads(res.data);
    } catch (err) {
      notifApiError(err);
      const errorMessage = err.response.data.detail || "Can't find this stock right now.";
      setSuggestions([]);
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  },
  300
);

export const fetchRedditSuggestions = debounce(
  async (
    query,
    { setSuggestions, setSuggestionsNames, setSuggestionsDetails, setSuggestionsImages, setLoading, setError }
  ) => {
    if (!query || query.length <= 2) {
      setSuggestions([]);
      setError(null);
      return;
    }

    setLoading(true);

    try {
      setError("");
      const res = await api.get("/subreddit_search/", {
        params: { subreddit: query },
      });
      setSuggestions(res.data.map((s) => s.name));
      setSuggestionsNames(res.data.map((s) => s.name));
      setSuggestionsImages(res.data.map((s) => s.icon));
      // prettier-ignore
      setSuggestionsDetails(res.data.map(s => `${kFormatter(s.subscribers, 0)} subscribers - ${s.description}`));
    } catch (err) {
      notifApiError(err);
      const errorMessage = err.response.data.detail || "Can't search Reddit right now.";
      setSuggestions([]);
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  },
  300
);

export const searchTwitterUser = debounce(
  async (
    query,
    {
      setSuggestions,
      setSuggestionsNames,
      setSuggestionsImages,
      setSuggestionsDetails,
      setSuggestionsPayloads,
      setLoading,
      setError,
    }
  ) => {
    if (!query || query.length === 0) {
      setSuggestions([]);
      setError(null);
      return;
    }

    setLoading(true);

    try {
      const res = await api.get("/twitter_user_search/", {
        params: { q: query },
      });
      setSuggestions(res.data.map((s) => s.screen_name));
      setSuggestionsNames(res.data.map((s) => s.name));
      setSuggestionsImages(res.data.map((s) => s.profile_image_url));
      setSuggestionsDetails(res.data.map((s) => `@${s.screen_name} - ${kFormatter(s.followers)} followers`));
      setSuggestionsPayloads(res.data);
    } catch (err) {
      notifApiError(err);
      const errorMessage = err.response.data.detail || "Can't search Twitter right now.";
      setSuggestions([]);
      setSuggestionsDetails([]);
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  },
  300
);

export const searchWeatherLocation = debounce(
  async (
    query,
    {
      setSuggestions,
      setSuggestionsNames,
      setSuggestionsImages,
      setSuggestionsDetails,
      setSuggestionsPayloads,
      setLoading,
      setError,
    }
  ) => {
    if (!query || query.length === 0) {
      setSuggestions([]);
      setError(null);
      return;
    }

    setLoading(true);

    try {
      const res = await api.get("/weather/search/", {
        params: { q: query },
      });

      setSuggestions(res.data.map((s) => s.title));
      setSuggestionsNames(res.data.map((s) => s.title));
      setSuggestionsDetails(res.data.map((s) => s.subtitle));
      setSuggestionsPayloads(res.data);
    } catch (err) {
      notifApiError(err);
      setSuggestions([]);
      setSuggestionsNames([]);
      setSuggestionsDetails([]);
      setSuggestionsPayloads([]);
    } finally {
      setLoading(false);
    }
  },
  300
);

export const searchYouTubeChannels = debounce(
  async (
    query,
    {
      setSuggestions,
      setSuggestionsNames,
      setSuggestionsDetails,
      setSuggestionsIcons,
      setSuggestionsImages,
      setLoading,
      setError,
    }
  ) => {
    if (!query || query.length <= 2) {
      setSuggestions([]);
      setError(null);
      return;
    }

    setLoading(true);

    try {
      setError("");
      const res = await api.get("/youtube_search/", { params: { q: query } });

      const suggestionNames = res.data.map((searchRes) => {
        if (searchRes.kind === "channel") {
          return `${searchRes.title}`;
        } else if (searchRes.kind === "playlist") {
          return `${searchRes.channel_title} | ${searchRes.title}`;
        } else {
          return null;
        }
      });

      setSuggestions(res.data.map((s) => s.playlist_id));
      setSuggestionsImages(res.data.map((s) => s.kind === "channel" && s.thumbnails?.default.url));
      setSuggestionsIcons(res.data.map((s) => s.kind === "playlist" && "playlist"));
      setSuggestionsDetails(res.data.map((s) => s.description));
      setSuggestionsNames(suggestionNames);
    } catch (err) {
      notifApiError(err);
      const errorMessage = err.response.data.detail || "Can't search Reddit right now.";
      setSuggestions([]);
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  },
  1000
);

export const searchRssFeeds = debounce(
  async (
    query,
    {
      setSuggestions,
      setSuggestionsNames,
      setSuggestionsDetails,
      setSuggestionsImages,
      setSuggestionsPayloads,
      setLoading,
      setError,
    }
  ) => {
    function updateSuggestions(feeds) {
      setSuggestions(feeds.map((s) => s.feed_url));
      setSuggestionsNames(feeds.map((s) => s.title));
      setSuggestionsDetails(feeds.map((s) => s.site_url));
      setSuggestionsImages(feeds.map((s) => avatarFromUrl(s.site_url)));
      setSuggestionsPayloads(feeds);
    }

    if (!query || query.length < 2) {
      setSuggestions([]);
      setError(null);
      return;
    }

    setLoading(true);
    setError("");

    const urlRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;
    if (query.match(urlRegex)) {
      // fetch feed info via typed url
      try {
        const res = await api.get("feed_fetcher/", { params: { url: query } });
        const feed = { title: res.data.title, feed_url: res.data.feed_url, site_url: res.data.site_url };
        updateSuggestions([feed]);
      } catch (err) {
        const errorMessage = err?.response?.data?.detail || "Can't find any RSS feed there.";
        setSuggestions([]);
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    } else {
      // search results in backend
      try {
        const res = await api.get("/rss_search/", { params: { q: query } });
        updateSuggestions(res.data);
      } catch (err) {
        const errorMessage = err?.response?.data?.detail || "Can't search RSS feeds right now.";
        setSuggestions([]);
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    }
  },
  500
);

export const searchPodcasts = debounce(
  async (
    query,
    {
      setSuggestions,
      setSuggestionsNames,
      setSuggestionsDetails,
      setSuggestionsImages,
      setSuggestionsPayloads,
      setLoading,
      setError,
    }
  ) => {
    function updateSuggestions(feeds) {
      setSuggestions(feeds.map((s) => s.name));
      setSuggestionsNames(feeds.map((s) => s.name));
      setSuggestionsDetails(feeds.map((s) => [s.category, s.author].filter(Boolean).join(" • ")));
      setSuggestionsImages(feeds.map((s) => s.image_url));
      setSuggestionsPayloads(feeds);
    }

    if (!query || query.length === 0) {
      setSuggestions([]);
      setError(null);
      return;
    }
    setLoading(true);

    const urlRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;
    if (query.match(urlRegex)) {
      // fetch feed info via typed url
      try {
        const res = await api.get("feed_fetcher/", { params: { url: query } })
        updateSuggestions(res.data);
      } catch (err) {
        const errorMessage = err?.response?.data?.detail || "Can't find any RSS feed there.";
        setSuggestions([]);
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const res = await api.get("/podcast_search/", {
          params: { q: query },
        });
        updateSuggestions(res.data)
      } catch (err) {
        notifApiError(err);
        const errorMessage = err.response.data.detail || "Can't search iTunes podcasts right now.";
        setSuggestions([]);
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    }
  },
  300
)