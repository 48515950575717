import {
  AnimatedContainer,
  Button,
  Checkbox,
  H4,
  Input,
  Modal,
  P1,
  P2,
  SegmentedControl,
  Stack,
  useBreakpoint,
  useConfig,
} from "@mailbrew/uikit";
import api from "dependencies/api";
import platform from "platform";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { authUserSelector } from "reducers/authReducer";
import supportLinks from "supportLinks";
import createMailtoLink from "utils/createMailtoLink";
import { capitalize } from "utils/string";
import ExternalLink from "./ExternalLink";
import StyledA from "./StyledA";

export default function ContactUsModal({ show, setShow }) {
  const config = useConfig();

  const [faqRead, setFaqRead] = useState(false);

  const user = useSelector(authUserSelector) || {};
  const { full_name, username, email, monetization } = user;

  const [message, setMessage] = useState("");
  const [ticketType, setTicketType] = useState("feedback");
  const [isUrgent, setIsUrgent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const hits = useBreakpoint([375, 480]);

  const inputRef = useRef();

  const subject = `${isUrgent ? "[!] " : ""}Mailbrew Support Request #${ticketType}`;
  const mailtoLink = createMailtoLink({
    to: "support@mailbrew.com",
    subject: subject,
    body: createBodyFromMessage(message, true),
  });

  // focus input on show
  useEffect(() => {
    if (show) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 50);
    }
  }, [show]);

  // reset on close after success
  useEffect(() => {
    if (success && !show) {
      setMessage("");
      setTicketType("feedback");
      setIsUrgent(false);
      setLoading(false);
      setSuccess(false);
    }
  }, [show, success]);

  function createBodyFromMessage(message, includeInstructions) {
    return `${message}

---
${includeInstructions ? "Please write your message above this line." : ""}
User: ${full_name} - ${username}
Email: ${email}
Type: ${capitalize(ticketType)}${isUrgent ? " (Urgent)" : ""}
Status: ${capitalize(monetization?.status)} (${capitalize(monetization?.data?.product_id)})
${typeof window !== "undefined" ? "Current page: " + window?.location?.href : ""}
${
  platform &&
  `Browser: ${platform.name} ${platform.version}
OS: ${platform.os.family} ${platform.os.version} (${platform.os.architecture})`
}
`;
  }

  function handleSendMessage() {
    if (message.trim().length === 0) {
      alert("Please, write your message before sending.");
      return;
    }

    setLoading(true);

    api
      .post("/support_request/", { subject, body: createBodyFromMessage(message) })
      .then((res) => {
        setSuccess(true);
        setTimeout(() => setShow(false), 700);
      })
      .catch((err) => {
        alert(
          err.response?.data.detail ?? "Something went wrong. You can get in touch with us at support@mailbrew.com."
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <Modal bottomSheet={hits[1]} width="460px" bottomSheetWidth="400px" show={show} setShow={setShow}>
      <H4 mb={3}>Contact us</H4>
      <AnimatedContainer startAnimatingAfter={200}>
        {!faqRead && (
          <Stack vertical w="100%" align="stretch" gap={3}>
            <P1>
              Please read our <ExternalLink href={supportLinks.help}>F.A.Q. and Help Pages</ExternalLink>, you'll
              probably find the answer to your question there.
            </P1>
            <P1>This way you'll help us save time, thanks 🙏</P1>
            <Stack vertical w="100%" align="stretch">
              <StyledA variant="white" icon="question" href={supportLinks.help} targetBlank>
                Read Help Pages
              </StyledA>
              <Button onClick={() => setFaqRead(true)} variant="secondary" icon="email">
                Haven't found an answer
              </Button>
            </Stack>
          </Stack>
        )}
        {faqRead && (
          <Stack vertical w="100%" align="stretch" gap={4}>
            <SegmentedControl
              options={["feedback", "bug", "billing", "other"]}
              optionsNames={["Feedback", "Bug", "Billing", "Other"]}
              active={ticketType}
              onOptionChange={setTicketType}
              backdropStyle={{
                ...config.SegmentedControl.backdropStyle,
                background: config.colors.c3,
              }}
            />
            <Input
              ref={inputRef}
              name="message"
              component="textarea"
              height="140px"
              type="text"
              placeholder="Write your message here..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              fontSize="15.5px"
            />
            <Button
              aStyle={{ width: "100%" }}
              onClick={() => !loading && !success && handleSendMessage()}
              icon={success ? "checkmarkCircle" : "sendBold"}
              color={success ? config.colors.success : undefined}
              w="100%"
              softLoading={loading}
            >
              {success ? "Sent" : "Send message"}
            </Button>
            <Stack align="center" mt={-2}>
              <P1 color={(c) => c.colors.c3} style={{ cursor: "pointer" }} onClick={() => setIsUrgent(!isUrgent)}>
                Is this an urgent matter?
              </P1>
              <Checkbox checked={isUrgent} onClick={() => setIsUrgent(!isUrgent)} />
            </Stack>
            <P2 align="center" color={(c) => c.colors.c4}>
              Need to attach something? <a href={mailtoLink}>Send us an email</a>.
            </P2>
          </Stack>
        )}
      </AnimatedContainer>
    </Modal>
  );
}
