import { getColorFromCssVariable, useConfig } from "@mailbrew/uikit";
import { motion } from "framer-motion";
import { useMemo } from "react";
import tinycolor from "tinycolor2";

export default function LoadingLogo({ color: providedColor, size = 48 }) {
  const config = useConfig();

  const color = getColorFromCssVariable(providedColor ?? config.colors.c5);
  const transparentColor = useMemo(() => tinycolor(color).setAlpha(0.5).toString(), [color]);

  return (
    <svg width={size} height={size} viewBox="0 0 118 118" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="logo">
        <path
          id="base"
          d="M105 69.63C103.993 77.4195 100.919 84.7973 96.0973 90.9976C91.2758 97.1979 84.8825 101.995 77.5812 104.89C70.2799 107.785 62.3367 108.672 54.5764 107.461C46.8161 106.249 39.5215 102.983 33.45 98H18C13.4913 98 9.1673 96.2089 5.97918 93.0208C2.79107 89.8327 1 85.5087 1 81C1 76.4913 2.79107 72.1673 5.97918 68.9792C9.1673 65.791 13.4913 64 18 64H100C100.713 63.9943 101.419 64.1412 102.071 64.4309C102.723 64.7206 103.305 65.1464 103.778 65.6796C104.252 66.2129 104.606 66.8413 104.817 67.5226C105.027 68.204 105.09 68.9225 105 69.63ZM18 88H24.47C21.6603 83.7235 19.6291 78.9839 18.47 74H18C16.1435 74 14.363 74.7375 13.0503 76.0502C11.7375 77.363 11 79.1435 11 81C11 82.8565 11.7375 84.637 13.0503 85.9497C14.363 87.2625 16.1435 88 18 88Z"
          fill={color}
        />
        <motion.path
          style={{ origin: "50% 100%" }}
          animate={{ scale: [1, 1.25], fill: [color, transparentColor] }}
          transition={{ repeat: Infinity, duration: 0.6, repeatType: "reverse" }}
          id="inner"
          d="M61.32 32C56.6198 32.0058 52.0451 33.5167 48.266 36.3114C44.487 39.1061 41.7023 43.0377 40.32 47.53C40.127 48.1584 40.0596 48.8186 40.1217 49.473C40.1838 50.1274 40.3742 50.7632 40.6821 51.344C41.3037 52.5171 42.366 53.3951 43.635 53.785C44.9041 54.1748 46.276 54.0446 47.4491 53.4229C48.6221 52.8013 49.5001 51.739 49.89 50.47C50.6444 48.0167 52.1655 45.8699 54.2299 44.3449C56.2943 42.8198 58.7934 41.9969 61.36 41.9969C63.9267 41.9969 66.4257 42.8198 68.4901 44.3449C70.5546 45.8699 72.0757 48.0167 72.83 50.47C73.0231 51.0977 73.3378 51.6812 73.7564 52.1873C74.175 52.6934 74.6891 53.112 75.2695 53.4194C75.8499 53.7268 76.4851 53.9168 77.1389 53.9787C77.7927 54.0405 78.4523 53.973 79.08 53.78C79.7077 53.5869 80.2913 53.2721 80.7973 52.8536C81.3034 52.435 81.7221 51.9209 82.0294 51.3405C82.3368 50.7601 82.5268 50.1249 82.5887 49.4711C82.6506 48.8173 82.5831 48.1577 82.39 47.53C81.0042 43.0262 78.209 39.0864 74.4159 36.2906C70.6227 33.4948 66.0321 31.9908 61.32 32Z"
        />
        <motion.path
          style={{ origin: "50% 100%" }}
          animate={{ scale: [1, 1.15], fill: [color, transparentColor] }}
          transition={{ repeat: Infinity, duration: 0.6, repeatType: "reverse", delay: 0.2 }}
          id="outer"
          d="M18.88 52.3C18.4116 51.7661 18.0624 51.1386 17.8555 50.4593C17.6486 49.7799 17.5887 49.0643 17.68 48.36C19.0455 37.7508 24.229 28.0024 32.261 20.938C40.293 13.8736 50.6233 9.97696 61.32 9.97696C72.0167 9.97696 82.347 13.8736 90.379 20.938C98.411 28.0024 103.594 37.7508 104.96 48.36C105.13 49.6808 104.768 51.0149 103.954 52.0688C103.14 53.1228 101.941 53.8102 100.62 53.98C99.2992 54.1497 97.9651 53.7878 96.9112 52.9739C95.8572 52.16 95.1697 50.9608 95 49.64C93.9503 41.437 89.9468 33.8977 83.7391 28.4337C77.5313 22.9697 69.5449 19.9556 61.275 19.9556C53.0051 19.9556 45.0187 22.9697 38.8109 28.4337C32.6032 33.8977 28.5997 41.437 27.55 49.64C27.426 50.5927 27.0302 51.4895 26.4099 52.2232C25.7896 52.9568 24.971 53.4962 24.0522 53.7768C23.1334 54.0574 22.1531 54.0674 21.2288 53.8055C20.3044 53.5436 19.4751 53.0209 18.84 52.3H18.88Z"
        />
      </g>
    </svg>
  );
}
