/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Box, useBreakpoint, useConfig } from "@mailbrew/uikit";
import { sourcesData } from "../data/sourcesData";
import { getSourceTitle } from "../utils/sources";
import StyledTooltip from "./StyledTooltip";

const SourcesBadges = ({ sources, sourceTypes: providedSourceTypes, size = 26, gap = 8, style }) => {
  const config = useConfig();

  const breakpointHit = useBreakpoint();

  const sourceThumbStyle = {
    width: size,
    height: size,
    border: `2px solid ${config.colors.bg0}`,
    overflow: "hidden",
    borderRadius: size,
  };

  const sourceTypes = (() => {
    let types = providedSourceTypes ?? (sources ? sources?.map((s) => s?.type) : []);

    // dedup
    types = Array.from(new Set(types));

    // filter for longer sources
    if (types.length > 3) {
      types = types.filter((t) => t !== "separator");
    }

    // additional filtering for longer sources
    if (types.length > 6) {
      types = types.filter((t) => t !== "today");
    }

    // additional filtering for longer sources
    if (types.length > 8) {
      types = types.filter((t) => t !== "text");
    }

    return types;
  })();

  if (!sourceTypes) {
    return null;
  }

  const sourceTitles = (() => {
    if (sources) {
      if (sources.length > 3) {
        return (
          sources
            .slice(0, 3)
            .map((s) => getSourceTitle(s))
            .join(", ") + "..."
        );
      } else {
        return sources.map((s) => getSourceTitle(s)).join(", ");
      }
    } else {
      return sourceTypes.map((type) => sourcesData[type].name).join(", ");
    }
  })();

  return (
    <Box maxW="100%" overflow="hidden" style={style}>
      <StyledTooltip maxWidth="auto" title={sourceTitles} style={{ maxWidth: "100%", display: "inline-flex" }}>
        {sourceTypes.map((s, index) => {
          const SourceThumb = sourcesData[s]?.thumb;
          const isLast = index === sourceTypes.length - 1;
          return SourceThumb ? (
            <Box key={index} display="inline-block" mr={isLast ? 0 : breakpointHit ? `-${gap}px` : `-${gap - 2}px`}>
              <SourceThumb style={sourceThumbStyle} />
            </Box>
          ) : null;
        })}
      </StyledTooltip>
    </Box>
  );
};

export default SourcesBadges;
