import { Box, Button, Grid, H4, notif, NotifWithButton, P1, Stack, useBreakpoint, useConfig } from "@mailbrew/uikit";
import Axios from "axios";
import api from "dependencies/api";
import useHandleShareUrl, { copyToClipboard } from "hooks/useHandleShareUrl";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { authUserSelector } from "reducers/authReducer";
import useSWR from "swr";
import urls from "urls";
import fetchNewsletterShareLink from "utils/fetchNewsletterShareLink";
import { fromNow } from "utils/formatDate";
import { keyInboxSourceMessageByID } from "utils/swrKeys";
import trimText from "utils/trimText";
import ExternalContentIFrame from "./ExternalContentIFrame";
import { FullWidthCard, FullWidthCardFooter } from "./FullWidthCard";
import ItemActionButtons from "./ItemActionButtons";
import { isValidKeyboardShortcutEvent } from "./KeyboardShortcutsManager";
import ReadingProgressIndicator from "./ReadingProgressIndicator";
import SenderDropDown from "./SenderDropDown";
import SmallSecondaryButton from "./SmallSecondaryButton";
import UnstyledA from "./UnstyledA";

const InboxMessageView = React.memo(
  ({
    // This component works by providing either a messageId or a whole message.
    messageId,
    message: providedMessage,
    index,
    src,
    fullTitle,
    subjectLinksToMessage,
    showCard,
    onMessageError,
    expandable = false,
    onClickDelete,
    onClickArchive,
    onHtmlReady,
    height = "180px",
    children,
    externalChildren,
    itemActionsOverride,
    withReadLater = true,
    withArchive = true,
    keepReadingEnabled: providedKeepReadingEnabled = true,
    showReadingProgressIndicator,
    actionsEnabled = true,
    mt = 0,
    mb = 5,
    style: providedStyle,
    noDarkMode,
    onKeyUp,
    isPublicSharePage,
  }) => {
    const config = useConfig();
    const router = useRouter();

    const user = useSelector(authUserSelector);
    const [expanded, setExpanded] = useState(expandable ? false : true);
    const containerRef = useRef(null);

    const swrKey = providedMessage
      ? keyInboxSourceMessageByID(providedMessage.message_id, src)
      : keyInboxSourceMessageByID(messageId, src);

    const { data: message, isValidating: loading, error: fetchingError, mutate: mutateMessage } = useSWR(swrKey, {
      initialData: providedMessage,
    });

    const isUserMessageOwner = user?.id === message?.user;

    useEffect(() => {
      if (fetchingError) {
        onMessageError && onMessageError();
      }
    }, [fetchingError, onMessageError]);

    function handleExpand() {
      const isClosing = expanded;
      const isOpening = !expanded;

      // scroll back on close
      if (isClosing && containerRef.current) {
        setTimeout(() => {
          containerRef.current.scrollIntoView({ block: "start" });
        }, 200);
      }

      // mark read on open
      if (isOpening && !message.reading_progress) {
        mutateMessage(() => {
          api.patch(keyInboxSourceMessageByID(message.message_id), { reading_progress: 0.2 });
          return { ...message, reading_progress: 0.2 };
        }, false);
      }

      setExpanded(!expanded);
    }

    const handleChangeReadingProgress = useCallback(() => {
      // don't change reading progress if user reading is not the owner
      if (!isUserMessageOwner || !providedKeepReadingEnabled) {
        return;
      }

      mutateMessage();
    }, [isUserMessageOwner, providedKeepReadingEnabled, mutateMessage]);

    /* --------------------------- get message content -------------------------- */
    // don't use SWR because message.content_url changes at each fetch and triggers an ugly re-render

    const [messageHtml, setMessageHtml] = useState(null);
    useEffect(() => {
      if (message && message.content_url) {
        Axios.get(message.content_url).then((res) => {
          setMessageHtml(res.data);
          onHtmlReady && onHtmlReady();
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message?.id]);

    const keepReadingEnabled = providedKeepReadingEnabled && !isPublicSharePage && isUserMessageOwner && messageHtml;

    const noOutline = showCard ? {} : { outline: "none" };

    function handleDelete() {
      message && onClickDelete(message.message_id);
    }

    function handleArchive(showNotification) {
      if (!message) return;

      const doArchive = () => onClickArchive?.(message.message_id, message.archived, showNotification);

      // closing it before archiving better preserves the scroll position in the messages list
      if (expandable && expanded) {
        containerRef.current.scrollIntoView({ block: "start" });
        setTimeout(doArchive, 700);
      } else {
        doArchive();
      }
    }

    const handleSave = async () => {
      try {
        await api.post("/add_read_later_item_via_url/", { url: message.read_url });
        notif.success(
          <NotifWithButton
            msg="Saved"
            action="View"
            onClick={() => {
              router.push(urls.saved());
              notif.dismiss();
            }}
          />
        );
      } catch (err) {
        const message = err?.response?.data?.detail;
        notif.error(message ?? "Oops, can't save your link.Please retry.");
      }
    };

    const isTextOnly = message?.content_type === 1;

    const handleEditHTMLElementBeforeInjection = (htmlElement) => {
      specificNewsletterFixes(htmlElement, isTextOnly);
      if (!user || isPublicSharePage) {
        disableUnsubscribeLinks(htmlElement);
      }
      setTimeout(() => {
        zoomOutOverflowingRootElement(htmlElement, isTextOnly);
      });
    };

    const lateralPadding = "6px";

    const style = {
      ...providedStyle,
      paddingLeft: lateralPadding,
      paddingRight: lateralPadding,
    };

    return (
      <Fragment>
        <FullWidthCard
          noCard={!showCard}
          ref={containerRef}
          minHeight={showCard && expanded && !messageHtml ? "100vh" : "auto"}
          mt={mt}
          mb={mb}
          pb={expanded ? 3 : 0}
          tabIndex={index ? index + 1 : 0}
          data-hotkey-index={index}
          onKeyUp={(e) => {
            if (!isValidKeyboardShortcutEvent(e)) return;

            if (e.key === "Enter" || e.key === "o") {
              handleExpand();
            } else if (e.key === "Backspace") {
              handleDelete();
            } else if (e.key === "e") {
              handleArchive();
            } else if (e.key === "s") {
              handleSave();
            } else if (onKeyUp) {
              onKeyUp(e);
            }
          }}
          style={{ scrollMargin: "40px", ...noOutline, ...style }}
        >
          {!loading && (
            <>
              <Box px={config.layout.padding}>
                <InboxMessageHeader
                  isPublicSharePage={isPublicSharePage}
                  message={message}
                  subjectLinksToMessage={subjectLinksToMessage}
                  fullTitle={fullTitle}
                  keepReadingEnabled={keepReadingEnabled}
                  noDarkMode={noDarkMode}
                  showCard={showCard}
                  actionsEnabled={actionsEnabled}
                  showReadingProgressIndicator={showReadingProgressIndicator}
                />
              </Box>
              <ExternalContentIFrame
                html={messageHtml}
                targetBlankLinks
                height={!expanded && height}
                style={style}
                transparent={false}
                editHTMLElementBeforeInjection={handleEditHTMLElementBeforeInjection}
                // ref={ref}
              />
              {expandable && (
                <FullWidthCardFooter padding={lateralPadding} noCard={!showCard} expanded={expanded}>
                  <InboxMessageFooter
                    message={message}
                    expanded={expanded}
                    handleExpand={handleExpand}
                    onClickDelete={handleDelete}
                    onClickArchive={onClickArchive && handleArchive}
                    actionsEnabled={actionsEnabled}
                    onChangeReadingProgress={handleChangeReadingProgress}
                    itemActionsOverride={itemActionsOverride}
                    withReadLater={withReadLater}
                    withArchive={withArchive}
                    onLinkSaved={() => handleArchive(false)}
                  />
                </FullWidthCardFooter>
              )}
            </>
          )}
          {typeof children === "function" ? children(message) : children}
        </FullWidthCard>
        {typeof externalChildren === "function" ? externalChildren(message) : externalChildren}
      </Fragment>
    );
  }
);

const InboxMessageHeader = ({
  message,
  subjectLinksToMessage,
  fullTitle,
  keepReadingEnabled,
  isPublicSharePage,
  noDarkMode,
  showCard,
  actionsEnabled,
  showReadingProgressIndicator,
}) => {
  const config = useConfig();
  const breakpointHit = useBreakpoint();
  const user = useSelector(authUserSelector);

  const color = noDarkMode ? "black" : config.colors.c1;

  if (!message) return null;

  return (
    <Stack vertical align={"center"} maxW="100%" overflow="hidden" gap={0} mb={3}>
      {subjectLinksToMessage ? (
        <EmailSubject fullTitle={fullTitle} color={color}>
          <Link href={message?.read_url_relative ?? ""}>
            <UnstyledA>{message.subject}</UnstyledA>
          </Link>
        </EmailSubject>
      ) : (
        <EmailSubject fullTitle={fullTitle} color={color}>
          {message.subject}
        </EmailSubject>
      )}
      <Stack maxW="100%" mt={1.5} align={breakpointHit ? "stretch" : "center"} noWrap overflow="hidden">
        <InlineMessageSender message={message} color={color} actionsEnabled={actionsEnabled} />
        {user && !isPublicSharePage && (
          <Stack noWrap>
            <a href={`mailto:${message.sender_email}?subject=Re:%20${encodeURI(message.subject)}`}>
              <SmallSecondaryButton noLabelOnMobile tooltip="Reply to author" icon="reply">
                Reply
              </SmallSecondaryButton>
            </a>
            <InboxMessageShareButton message={message} />
          </Stack>
        )}
      </Stack>
      {keepReadingEnabled && showReadingProgressIndicator && (
        <ReadingProgressIndicator progress={message.reading_progress} />
      )}
    </Stack>
  );
};

const InboxMessageShareButton = ({ message }) => {
  const handleShare = useHandleShareUrl();

  function handleClick() {
    fetchNewsletterShareLink(message.message_id, handleShare);
  }

  return (
    <SmallSecondaryButton noLabelOnMobile icon="shareAlt" onClick={handleClick}>
      Share
    </SmallSecondaryButton>
  );
};

const InlineMessageSender = ({ message, color, actionsEnabled }) => {
  const config = useConfig();
  const hit = useBreakpoint(400);
  const user = useSelector(authUserSelector);

  const Content = () => (
    <Stack gap={1.7} vAlign="center" noWrap overflow="hidden" maxW="100%" style={{ flex: "1 999 0" }}>
      <img
        src={message.avatar_url}
        alt={message.sender_name}
        style={{
          flex: "0 0 22px",
          width: "22px",
          height: "22px",
          borderRadius: "22px",
          boxShadow: "inset 0px 0px 1px rgba(0,0,0,0.7)",
        }}
      />
      <P1 size="14px" bold color={color ?? config.colors.c1} noWrap overflow="hidden">
        {hit ? trimText(message.sender_name, 20) : message.sender_name}
      </P1>
      <P1 align="center" size="14px" color={config.colors.c4} noWrap>
        {fromNow(message.date, true)}
      </P1>
    </Stack>
  );

  if (user && actionsEnabled) {
    return (
      <SenderDropDown id={message.message_id} style={{ maxWidth: "100%", overflow: "hidden" }}>
        <Content />
      </SenderDropDown>
    );
  } else {
    return <Content />;
  }
};

const InboxMessageFooter = ({
  message,
  expanded,
  handleExpand,
  onClickDelete,
  onClickArchive,
  actionsEnabled,
  children,
  itemActionsOverride,
  withReadLater,
  withArchive,
  onLinkSaved,
}) => {
  const config = useConfig();
  const hit = useBreakpoint();
  const router = useRouter();

  function handleClickShare() {
    fetchNewsletterShareLink(message.message_id, copyToClipboard);
  }

  function handleOpen() {
    router.push(message.read_url_relative);
  }

  return (
    <Grid columns="1fr auto 1fr" position="relative">
      {children}
      <div />
      <Stack align="center">
        <Button
          small={hit}
          icon={expanded ? "chevronUp" : "chevronDown"}
          variant={!expanded ? "white" : null}
          color={expanded ? config.colors.c3 : undefined}
          onClick={handleExpand}
        >
          {expanded ? "Close" : "Read"}
        </Button>
      </Stack>
      <Stack align="right" noWrap>
        {actionsEnabled && !itemActionsOverride && (
          <ItemActionButtons
            title={message.subject}
            url={message.read_url}
            onDeleteClick={onClickDelete}
            withArchive={withArchive}
            onArchiveClick={onClickArchive}
            isArchived={message.archived}
            withReadLater={withReadLater}
            onLinkSaved={onLinkSaved}
            extraOptions={[
              { id: "open", name: "Open", icon: "open", handler: handleOpen },
              { id: "link", name: "Copy link", icon: "link", handler: handleClickShare },
            ]}
          />
        )}
        {itemActionsOverride && itemActionsOverride}
      </Stack>
    </Grid>
  );
};

const EmailSubject = ({ fullTitle, children, breakpointHit, ...otherProps }) => {
  const config = useConfig();
  return (
    <H4
      align={breakpointHit ? "left" : "center"}
      color={config.colors.c1}
      noWrap={!fullTitle}
      width="100%"
      overflow={fullTitle ? "auto" : "hidden"}
      {...otherProps}
    >
      {children}
    </H4>
  );
};

function disableUnsubscribeLinks(htmlElement) {
  // Disable unsubscribe links
  Array.from(htmlElement.querySelectorAll("a")).forEach((link) => {
    if (
      link.href?.toLowerCase().indexOf("unsubscribe") !== -1 ||
      link.innerText?.toLowerCase().indexOf("unsubscribe") !== -1
    ) {
      link.href = "#";
      link.style.pointerEvents = "none";
      link.style.userSelect = "none";
      link.style.pointer = "default";
      link.onclick = "null";
    }
  });
}

function specificNewsletterFixes(htmlElement, isTextOnly) {
  // Force background color to white when there's no background color
  setTimeout(() => {
    const bgColor = window.getComputedStyle(htmlElement.querySelector("body")).backgroundColor;
    if (
      !bgColor ||
      bgColor === "" ||
      bgColor === "transparent" ||
      bgColor === "rgba(0, 0, 0, 0)" ||
      bgColor === "rgb(0, 0, 0)"
    ) {
      styleElements(
        "body",
        (body) => {
          body.style.background = "white";
        },
        htmlElement
      );
    }
  });

  if (isTextOnly) {
    styleElements(
      "body",
      (body) => {
        body.style.fontFamily =
          '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';
        body.style.whiteSpace = "pre-line";
        body.style.lineHeight = "1.4";
        body.style.fontSize = "15px";
      },
      htmlElement
    );
  }

  // Fixed tables
  styleElements(
    `table`,
    (div) => {
      div.style.minWidth = "auto";
    },
    htmlElement
  );

  // Ruby Weekly and full height tables
  styleElements(
    `table[style*="height: 100% !important;"]`,
    (div) => {
      div.style.height = "auto";
    },
    htmlElement
  );

  // Margins for plan text emails
  styleElements(
    "body",
    (body) => {
      body.style.margin = "0px";
      // body.style.padding = "12px";
    },
    htmlElement,
    (body) => !body.querySelector("table")
  );

  styleElements("img", (img) => prependCss(img, "max-width: 100%;"), htmlElement);

  // Substack
  // The Spotify widget made the layout overflow horizontally
  styleElements(
    `.email-body .spotify-wrap`,
    (div) => {
      div.style.maxWidth = "100%";
      div.style.overflow = "hidden";
    },
    htmlElement
  );
  styleElements(
    `.email-body .spotify-wrap span`,
    (span) => {
      span.style.whiteSpace = "unset";
    },
    htmlElement
  );

  // Convertkit fixed divs
  styleElements(
    `div[style*="margin:0 auto;width:480px"]`,
    (div) => {
      div.style.maxWidth = "100%";
    },
    htmlElement
  );

  // Maker Mind
  styleElements(
    `table[role="presentation"]>tbody>tr>td>div.email-container[style*="padding-left:20px;padding-right:20px;max-width:600px"]`,
    (div) => {
      div.style.paddingTop = "0px";
      div.style.paddingLeft = "2px";
      div.style.paddingRight = "2px";
      div.style.width = "94vw";
    },
    htmlElement
  );

  // Shaan
  styleElements(
    `table[role="presentation"]>tbody>tr>td>div.email-container>div.email-body>div.email-content[style*="padding:20px 30px"]`,
    (div) => {
      div.style.paddingLeft = "2px";
      div.style.paddingRight = "2px";
    },
    htmlElement
  );

  // Canny
  styleElements(
    `.main-td > table.content[width="480"]`,
    (table) => {
      table.width = "fixed";
      table.style.width = "100%";
      table.style.maxWidth = "480px";
    },
    htmlElement
  );

  // Trends.vc Fix
  styleElements(
    ".mcnTextContentContainer",
    (container) => {
      container.style.tableLayout = "fixed";
    },
    htmlElement
  );

  // Matt D'Avella Fix, and in general centering fixed containers
  styleElements(
    `[style*="max-width: 600px !important;"]`,
    (container) => {
      container.style.marginLeft = "auto";
      container.style.marginRight = "auto";
    },
    htmlElement
  );

  // Dave Perell
  styleElements(
    `.message-content[style*="max-width:600px"]`,
    (content) => {
      content.style.marginLeft = "auto";
      content.style.marginRight = "auto";
    },
    htmlElement
  );

  // Regular Reveries
  styleElements(
    `.newsletter-container`,
    (container) => {
      container.style.marginLeft = "auto";
      container.style.marginRight = "auto";
    },
    htmlElement
  );
}

function zoomOutOverflowingRootElement(htmlElement) {
  const windowWidth = window.innerWidth;

  function applyFix(el) {
    const elWidth = el?.offsetWidth;

    if (elWidth > 0) {
      const zoomRatio = (window.innerWidth - 20) / elWidth;

      if (zoomRatio < 0.95) {
        styleElements(
          "body",
          (body) => {
            body.style.zoom = Math.max(zoomRatio, 0.5);
          },
          htmlElement
        );
      }
    }
  }

  const allTables = Array.from(htmlElement.querySelectorAll("table"));
  let tableWidths = allTables.map((table) => table.offsetWidth);
  tableWidths = tableWidths.filter((w) => w > windowWidth && w < 1400);

  if (tableWidths.length > 0) {
    const indexOfMax = getIndexOfMax(tableWidths);
    const foundTable = allTables[indexOfMax];
    applyFix(foundTable);
    return;
  }

  const otherRootElements = [
    ...Array.from(htmlElement.querySelectorAll("body > div")),
    ...Array.from(htmlElement.querySelectorAll("body > center")),
  ];

  for (const div of otherRootElements) {
    if (Array.from(div.children).length > 0) {
      applyFix(div);
      return;
    }
  }
}

function getIndexOfMax(arr) {
  if (arr.length === 0) {
    return -1;
  }

  var max = arr[0];
  var maxIndex = 0;

  for (var i = 1; i < arr.length; i++) {
    if (arr[i] > max) {
      maxIndex = i;
      max = arr[i];
    }
  }

  return maxIndex;
}

function prependCss(el, css) {
  el.style.cssText = `${css} ${el.style.cssText}`;
}

function styleElements(selector, handler, htmlElement, condition) {
  if (!htmlElement) return;
  const elements = htmlElement.querySelectorAll(selector);
  if (!elements) return;

  elements.forEach((el) => {
    if (el && (condition ? condition(el) : true)) {
      handler(el);
    }
  });
}

export default InboxMessageView;
