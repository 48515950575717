import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);
dayjs.extend(isYesterday);
dayjs.extend(isToday);

export function formatDate(date, format = "MMM D, YYYY") {
  return dayjs(date).format(format);
}

export function fromNow(date, short) {
  let fromNowString = dayjs(date).fromNow();

  if (short) {
    fromNowString = fromNowString.replace(" minutes", "m");
    fromNowString = fromNowString.replace(" hours", "h");
    fromNowString = fromNowString.replace("a day", "1d");
    fromNowString = fromNowString.replace(" days", "d");
  }

  return fromNowString;
}

export function fromNowOrDate(date, daysThreshold = 3, format = "MMM D, YYYY") {
  const diff = dayjs().diff(dayjs(date), "day");
  if (diff >= daysThreshold) return "on " + dayjs(date).format(format);
  return dayjs(date).fromNow();
}

export function toNow(date) {
  return dayjs(date).toNow();
}

export function isDateYesterday(date) {
  return dayjs(date).isYesterday(date);
}

export function isDateToday(date) {
  return dayjs(date).isToday(date);
}
