import { Button, MediaQuery, P1, useBreakpoint, useConfig } from "@mailbrew/uikit";
import Link from "next/link";
import { pluralize } from "utils/pluralize";
import urls from "../urls";
import { usePaywallState } from "./PaywallStateProvider";

export default function MonetizationStatusBanner() {
  const config = useConfig();

  const { user, monetization, setPaywallModalShown } = usePaywallState();

  function showPaywall() {
    setPaywallModalShown(true);
  }

  if (!user) return null;

  const monetizationStatus = monetization.status;
  const wasFree = user?.was_free ?? false;

  const linkStyle = {
    color: config.colors.accent1,
    border: "none",
    fontWeight: "600",
  };

  const paragraphProps = { size: "15px", weight: "500", color: config.colors.c1, align: "center" };
  const isTrialing = monetizationStatus === "trialing";
  const isTrialEnded = monetizationStatus === "trial_ended";
  const trialDaysLeft = user.monetization.data.days_left;

  if (isTrialing) {
    return (
      <Container>
        <P1 {...paragraphProps}>
          <MediaQuery showAbove={640} display="inline">
            You have{" "}
          </MediaQuery>
          {trialDaysLeft} {pluralize("day", trialDaysLeft)} left in your trial.{" "}
          <Button variant="link" style={linkStyle} onClick={showPaywall}>
            Subscribe here.
          </Button>
        </P1>
      </Container>
    );
  }

  if (isTrialEnded) {
    return (
      <Container variant="red">
        <P1 {...paragraphProps}>
          {wasFree ? "Free plan discontinued" : "Trial ended."}{" "}
          <Button variant="link" style={linkStyle} onClick={showPaywall}>
            Subscribe now.
          </Button>
        </P1>
      </Container>
    );
  }

  if (monetizationStatus === "canceled") {
    return (
      <Container variant="red">
        <P1 {...paragraphProps}>
          Invalid Subscription.{" "}
          <Button variant="link" style={linkStyle} onClick={showPaywall}>
            Subscribe again.
          </Button>
        </P1>
      </Container>
    );
  }

  if (monetizationStatus === "canceled_with_access" && monetization.data.current_period_end_days_left < 30) {
    return (
      <Container variant="red">
        <P1 {...paragraphProps}>
          Subscription canceled.{" "}
          <Button variant="link" style={linkStyle} onClick={showPaywall}>
            Reactivate it.
          </Button>
        </P1>
      </Container>
    );
  }

  if (monetizationStatus === "past_due") {
    return (
      <Container variant="red">
        <P1 {...paragraphProps}>
          Last charge failed.{" "}
          <Link href={urls.settings_billing()} passHref>
            <a style={linkStyle}>Fix payment method.</a>
          </Link>
        </P1>
      </Container>
    );
  }

  return null;
}

const Container = ({ children, variant = "lightOrange" }) => {
  const config = useConfig();
  const hit = useBreakpoint(375);

  let bgColor;
  let borderColor;

  if (variant === "lightOrange") {
    bgColor = config.colors.lightOrange;
    borderColor = config.colors.lightOrangeBorder;
  } else if (variant === "red") {
    bgColor = config.colors.red;
    borderColor = config.colors.redBorder;
  }

  return (
    <div
      id="monetization-banner"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zoom: hit ? 0.9 : 1,
        paddingLeft: config.layout.padding,
        paddingRight: config.layout.padding,
        borderBottom: `1px solid ${borderColor}`,
        height: "34px",
        background: bgColor,
      }}
    >
      {children}
    </div>
  );
};
