/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { useDarkMode } from "@mailbrew/uikit";

const Highlight = (props) => {
  const [darkMode] = useDarkMode();

  return (
    <span
      css={css`
        display: inline-block;
        position: relative;
        z-index: 2;
        ::before {
          content: "";
          position: absolute;
          top: 0px;
          left: -3px;
          right: -3px;
          bottom: 0px;
          background: ${darkMode ? "hsla(50, 60%, 15%)" : "hsla(45, 100%, 85%)"};
          z-index: -1;
          transform: skewX(-8deg) rotate(-0.5deg);
          border-radius: 3px;
        }
      `}
    >
      {props.children}
    </span>
  );
};

export default Highlight;
