const supportLinks = {
  help: "https://help.mailbrew.com/",
  forwardGmail: "https://help.mailbrew.com/forward",
  markdown: "https://help.mailbrew.com/markdown",
  stopSpam: "https://help.mailbrew.com/spam",
  twitterSearch: "https://help.mailbrew.com/twitter-search",
  substack: "http://help.mailbrew.com/substack",
  manageNewsletters: "http://help.mailbrew.com/newsletters",
  twitterPermissions: "https://help.mailbrew.com/twitter-permissions",
};

export default supportLinks;
