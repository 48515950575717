import { useConfig } from "@mailbrew/uikit";
import Link from "next/link";
import { AvatarImage } from "./UserAvatarImage";

const UserAvatar = (props) => {
  const { user, size = "1.8em", style, linksToUser = false } = props;

  const config = useConfig();

  const wrapperStyle = {
    width: size,
    height: size,
  };

  const imageStyle = {
    width: size,
    height: size,
    borderRadius: size,
    background: config.colors.c5,
    ...style,
  };

  const userUrl = `/${user?.username}`;

  if (linksToUser) {
    return (
      <Link href={userUrl} style={wrapperStyle}>
        <AvatarImage src={user?.profile?.image_url} style={imageStyle} />
      </Link>
    );
  } else {
    return <AvatarImage src={user?.profile?.image_url} style={imageStyle} />;
  }
};

export default UserAvatar;
