import { notif, NotifWithButton, Stack, useDetectBrowser } from "@mailbrew/uikit";
import api from "dependencies/api";
import { useRouter } from "next/router";
import { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import urls from "urls";
import MoreOptionsMenu from "./MoreOptionsMenu";
import SmallActionButton from "./SmallActionButton";

export default function ItemActionButtons({
  title,
  url,
  shareUrl,
  isArchived = false,
  withReaderMode,
  withReadLater = true,
  onLinkSaved,
  withArchive,
  withShare = false,
  withDelete = true,
  onReaderModeClick,
  onArchiveClick,
  onDeleteClick,
  onShareClick,
  extraOptions = [],
  ...otherProps
}) {
  const moreOptions = [
    ...extraOptions,
    withDelete ? { id: "delete", name: "Delete", icon: "trash", handler: onDeleteClick } : null,
  ].filter((el) => el);

  return (
    <Stack noWrap gap={gap + 1} vAlign="center" align="right" w="100%" {...otherProps}>
      <MoreOptionsMenu options={moreOptions} />
      <Stack gap={0} align="right" noWrap mr={-gap}>
        {withShare && (shareUrl || url) && (
          <ShareSmallActionButton
            tooltip="Share"
            icon="shareAlt"
            title={title}
            url={shareUrl ?? url}
            onShareClick={onShareClick}
            mr={gap}
          />
        )}
        {withReadLater && url && <ReadLaterSmallActionButton url={url} mr={gap} onLinkSaved={onLinkSaved} />}
        {!isArchived && withReaderMode && url && (
          <ReaderModeSmallActionButton onClick={() => onReaderModeClick(url)} mr={gap} />
        )}
        {withArchive && onArchiveClick && (
          <ArchiveSmallActionButton isArchived={isArchived} onClick={onArchiveClick} mr={gap} />
        )}
      </Stack>
    </Stack>
  );
}

export const ShareSmallActionButton = (props) => {
  const { title, text, icon, url, tooltip, style, onShareClick, children, ...otherProps } = props;

  const { hasTouch } = useDetectBrowser();

  if (navigator.share && hasTouch) {
    return (
      <SmallActionButton
        tooltip={tooltip}
        style={style}
        icon={icon}
        onClick={() => {
          if (onShareClick) {
            onShareClick();
          } else {
            try {
              navigator.share({
                title: title,
                text: text,
                url: url,
              });
            } catch (err) {
              // navigator.share throws if the user cancels sharing
            }
          }
        }}
        {...otherProps}
      >
        {children}
      </SmallActionButton>
    );
  } else {
    return (
      <CopyToClipboard
        text={url}
        onCopy={() => notif.success("Link copied")}
        style={{ lineHeight: 0, cursor: "pointer" }}
      >
        <SmallActionButton icon={icon} tooltip={tooltip} style={style} {...otherProps}>
          {children}
        </SmallActionButton>
      </CopyToClipboard>
    );
  }
};

export const ReaderModeSmallActionButton = ({ onClick, mr, ml }) => {
  return <SmallActionButton icon="text" onClick={onClick} mr={mr} ml={ml} />;
};

export const ReadLaterSmallActionButton = ({ url, mr, onLinkSaved }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const router = useRouter();

  const handleAddURL = async () => {
    try {
      setLoading(true);
      await api.post("/add_read_later_item_via_url/", { url });
      notif.success(
        <NotifWithButton
          msg="Saved"
          action="View"
          onClick={() => {
            router.push(urls.saved());
            notif.dismiss();
          }}
        />
      );
    } catch (err) {
      const message = err?.response?.data?.detail;
      notif.error(message ?? "Oops, can't save your link.Please retry.");
    } finally {
      setLoading(false);
      setSuccess(true);
      onLinkSaved && onLinkSaved();
    }
  };

  return (
    <SmallActionButton
      softLoading={loading}
      icon={success ? "checkmark" : "bookmark"}
      onClick={handleAddURL}
      tooltip="Save for later"
      mr={mr}
    />
  );
};

export function ArchiveSmallActionButton({ isArchived = false, onClick, mr }) {
  return (
    <SmallActionButton
      tooltip={isArchived ? "Put back" : "Archive"}
      icon={isArchived ? "arrowLeft" : "archiveBold"}
      onClick={onClick}
      mr={mr}
    />
  );
}

const gap = 2;
