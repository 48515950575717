import { notif, NotifWithButton, SimpleDropDown, useConfig } from "@mailbrew/uikit";
import api from "dependencies/api";
import useSWR, { mutate } from "swr";
import notifApiError from "utils/notifApiError";
import { keySenderOptionById, keySenderOptionByMessageId, keyUserInboxTaBs } from "utils/swrKeys";
import SenderCard from "./SenderCard";

export default function SenderDropDown({ children, id, style }) {
  const config = useConfig();

  // get the id of the sender option and then fetch from the usual endpoint for easier mutation handling
  const { data: _senderOption } = useSWR(keySenderOptionByMessageId(id));
  const { data: senderOption, mutate: mutateSender } = useSWR(_senderOption && keySenderOptionById(_senderOption.id));

  const handleSetFav = async (updatedSender) => {
    const senderId = updatedSender.id;

    try {
      await api.patch(keySenderOptionById(senderId), { favorited: updatedSender.favorited });
    } catch (err) {
      notifApiError(err, "Error updating newsletter");
      return;
    }

    mutateSender((cache) => ({ ...cache, favorited: updatedSender.favorited }), false);
    mutate(keyUserInboxTaBs);
  };

  const handleUpdateScreeningStatus = async (senderOption) => {
    let undoValue = null;

    mutateSender((cache) => {
      undoValue = cache;
      return { ...cache, screen_out: !cache.screen_out };
    }, false);

    try {
      await api.patch(keySenderOptionById(senderOption.id), { screen_out: senderOption.screen_out });
    } catch (err) {
      notifApiError(err, "Error updating newsletter");
      return;
    }

    notif.success(
      <NotifWithButton
        msg={`${senderOption.screen_out ? "Blocked" : "Allowed"} emails from ${
          senderOption?.sender?.display_name ?? "this sender"
        }`}
        action="Undo"
        onClick={async (n) => {
          mutateSender(undoValue, false);
          notif.dismiss(n.id);
          await api.patch(keySenderOptionById(senderOption.id), {
            screen_out: !senderOption.screen_out,
          });
        }}
      />
    );
  };

  if (!senderOption) {
    return children;
  }

  return (
    <SimpleDropDown
      style={{ padding: config.Card.smallPadding, ...style }}
      innerStyle={{ overflow: "hidden" }}
      width="300px"
      content={() => (
        <SenderCard
          emailInsteadOfTime
          justChildren
          showPreview={false}
          senderOption={senderOption}
          onSetFav={handleSetFav}
          onScreenOutUpdate={handleUpdateScreeningStatus}
        />
      )}
    >
      {/* children are always shown and when clicked open the dropdown */}
      {children}
    </SimpleDropDown>
  );
}
