import { DropDownMenu, useConfig } from "@mailbrew/uikit";

export default function MoreOptionsMenu({ options }) {
  const config = useConfig();
  if (!options || options?.length < 1) return null;
  return (
    <DropDownMenu
      icon="dots"
      iconColor={config.colors.c4}
      options={options.map((el) => el.id ?? el.value ?? el.name)}
      optionsNames={options.map((el) => el.name)}
      optionsIcons={options.map((el) => el.icon)}
      onSelect={(option) => options.find((el) => el.id === option)?.handler({ askConfirmation: false })}
    />
  );
}
