import { Box, CircleButton, Tooltip, useConfig } from "@mailbrew/uikit";
import useBiggerTouchButtonsStyle from "hooks/useBiggerTouchButtonsStyle";

export default function SmallActionButton({
  tooltip,
  noMobileTooltip = true,
  size = 30,
  icon,
  onClick,
  style,
  loading,
  softLoading,
  color,
  variant,
  ...otherProps
}) {
  const config = useConfig();

  const btnStyle = useBiggerTouchButtonsStyle();
  const btn = (
    <CircleButton
      icon={icon}
      color={color || config.colors.c3}
      hoverColor={color || config.colors.c2}
      onClick={onClick}
      variant={variant ?? (!color && "white")}
      size={size + "px"}
      iconSize={size < 30 ? "14px" : "16px"}
      loading={loading}
      softLoading={softLoading}
      style={{ ...btnStyle, ...style }}
    />
  );

  if (tooltip) {
    return (
      <Box {...otherProps}>
        <Tooltip noMobile={noMobileTooltip} title={tooltip} style={{ lineHeight: 0 }}>
          {btn}
        </Tooltip>
      </Box>
    );
  } else {
    return <Box {...otherProps}>{btn}</Box>;
  }
}
