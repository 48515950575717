import {
  Box,
  Button,
  Grid,
  H2,
  H3,
  Icon,
  Modal,
  P1,
  P2,
  SimpleDropDown,
  SmallText,
  Stack,
  useBreakpoint,
  useConfig,
  useDetectBrowser,
} from "@mailbrew/uikit";
import { motion } from "framer-motion";
import usePwa from "hooks/usePwa";
import dockImg from "images/pwa/dock.png";
import IOsIcon from "images/pwa/ios.png";
import { useRouter } from "next/router";
import { Fragment, useState } from "react";
import Script from "react-load-script";
import { useDispatch } from "react-redux";
import plausible from "utils/plausible";
import { logout } from "../reducers/authReducer";
import urls from "../urls";
import ContactUsModal from "./ContactUsModal";
import KeyboardShortcutsModal from "./KeyboardShortcutsModal";
import { HEADER_MOBILE_BREAKPOINT } from "./Page";
import { usePaywallState } from "./PaywallStateProvider";
import ProfileImageWithUpload from "./ProfileImageWithUpload";
import UserAvatar from "./UserAvatar";

const UserMenu = (props) => {
  const { zIndex, ...otherProps } = props;

  const config = useConfig();
  const hit = useBreakpoint(HEADER_MOBILE_BREAKPOINT);
  const dispatch = useDispatch();
  const router = useRouter();

  const size = hit ? "30px" : "28px";

  const { user, monetization, productId: userPlanId } = usePaywallState();

  const [hotKeysModalShown, setHotKeysModalShown] = useState(false);
  const [contactUsShown, setContactUsShown] = useState(false);

  const isFreemium = user?.config?.freemium;

  const showUpgradeButton = !(monetization?.status === "subscribed");

  const showUpgradedHeader = monetization?.status === "subscribed" && isFreemium;
  const isPatron = userPlanId === "patron";

  const userMenuHeader = showUpgradedHeader ? (
    <DropDownHeader>
      {isPatron ? (
        <P2 align="center" weight="600" color={config.colors.accent1}>
          <Icon name="starBold" size="15px" currentColor offset="-2px" mr="4px" />
          You're a Patron
        </P2>
      ) : (
        <P2 align="center" weight="600" color={config.colors.success}>
          <Icon name="checkmarkCircle" size="15px" currentColor offset="-2px" mr="4px" />
          You've upgraded!
        </P2>
      )}
    </DropDownHeader>
  ) : undefined;

  let userMenuOptions = [
    showUpgradeButton && {
      value: "upgrade",
      name: "Upgrade",
      icon: "starBold",
      handler: () => router.push(urls.upgrade()),
    },
    {
      value: "profile",
      name: "Profile",
      icon: "userCircleBold",
      handler: () => router.push(urls.profile(user?.username)),
    },
    {
      value: "changelog",
      name: "Changelog",
      icon: "content",
      href: "https://changelog.mailbrew.com",
    },

    {
      value: "requests",
      name: "Feature Requests",
      icon: "lightningAlt",
      noltButton: true,
    },
    {
      value: "affiliate",
      name: user?.is_affiliate ? "Affiliate" : "Earn with Mailbrew",
      icon: "heartBold",
      handler: () => router.push(urls.affiliate()),
    },
    {
      value: "hotkeys",
      name: "Shortcuts",
      icon: "commandAlt",
      handler: () => setHotKeysModalShown(true),
    },
    {
      value: "settings",
      name: "Settings",
      icon: "settingsAlt",
      handler: () => router.push(urls.settings()),
    },
  ].filter((o) => o);

  /* ----------------------------------- PWA ---------------------------------- */

  const { canInstallPwa, pwaInstallPrompt } = usePwa();
  const isPwaCompatibleBrowser = !!pwaInstallPrompt && !!canInstallPwa;

  const [userDismissedPrompt, setUserDismissedPrompt] = useState(false);

  const { isPwa, isIos, isMac, browser } = useDetectBrowser();

  const [iOsInstallModalShown, setIOsInstallModalShown] = useState(false);
  const [macInstallModalShown, setMacInstallModalShown] = useState(false);

  const handlePwaInstall = () => {
    plausible.track("User Menu Click", { option: "Install" });
    // PWA Compatible browser
    if (isPwaCompatibleBrowser) {
      pwaInstallPrompt.prompt();
      pwaInstallPrompt.userChoice.then((result) => {
        setUserDismissedPrompt(true);
      });
    } else if (isIos) {
      setIOsInstallModalShown(true);
    } else if (isMac && browser === "safari") {
      setMacInstallModalShown(true);
    }
  };

  const showInstallButton = (() => {
    if (isPwa || userDismissedPrompt) return false;

    if (!isPwaCompatibleBrowser && !(browser === "safari" || isIos)) return false;

    return true;
  })();

  if (showInstallButton) {
    userMenuOptions.splice(4, 0, {
      value: "install",
      name: "Install",
      icon: "arrowDownBold",
      handler: () => handlePwaInstall(),
    });
  }

  function handleScriptLoaded() {
    const { nolt } = window;
    nolt("init", { selector: "#nolt-button", url: "https://mailbrew.nolt.io" });
    nolt("identify", { jwt: user.nolt_token });
  }

  if (!user) {
    return null;
  }

  return (
    <Fragment>
      <Box zIndex={zIndex} {...otherProps}>
        <SimpleDropDown
          width="240px"
          style={{ overflow: "hidden", padding: "8px 10px" }}
          triggerStyle={{ lineHeight: 0 }}
          content={({ setShow }) => (
            <>
              {userMenuHeader}
              <Script url={"https://cdn.nolt.io/widgets.js"} onLoad={handleScriptLoaded} />
              <Stack vertical>
                <Stack vertical align="center" w="100%" mb={2} gap={1}>
                  <ProfileImageWithUpload size={50} />
                  <SmallText align="center">Click to change</SmallText>
                </Stack>
                <Box pl={0.5}>
                  {userMenuOptions.map((option, i) => (
                    <DropDownOption key={i} option={option} index={i} setShow={setShow} />
                  ))}
                </Box>
                <DropDownFooter>
                  <Grid w="100%" gap={2}>
                    <Button
                      onClick={() => {
                        setContactUsShown(true);
                        setShow(false);
                      }}
                      variant={["secondary", "small"]}
                      icon="wave"
                    >
                      Help
                    </Button>
                    <Button
                      onClick={() => {
                        dispatch(logout());
                        setShow(false);
                      }}
                      variant={["secondary", "small"]}
                      icon="logout"
                    >
                      Log out
                    </Button>
                  </Grid>
                </DropDownFooter>
              </Stack>
            </>
          )}
        >
          <UserAvatar user={user} size={size} />
        </SimpleDropDown>
      </Box>
      <MacInstallModal show={macInstallModalShown} setShow={setMacInstallModalShown} />
      <IOsInstallModal show={iOsInstallModalShown} setShow={setIOsInstallModalShown} />
      <KeyboardShortcutsModal show={hotKeysModalShown} setShow={setHotKeysModalShown} />
      <ContactUsModal show={contactUsShown} setShow={setContactUsShown} />
    </Fragment>
  );
};

const DropDownOption = ({ option, setShow, index }) => {
  const config = useConfig();

  const handleSelectOption = () => {
    if (option.noltButton) return;

    setShow(false);

    if (option.href) {
      window.open(option.href, "_blank");
    } else if (option.handler) {
      option.handler();
    }
  };

  return (
    <NoltButtonWrapper isNoltButton={option.noltButton}>
      <Stack style={{ cursor: "pointer" }} onClick={handleSelectOption} noWrap mt={index === 0 ? 0 : 2.5}>
        <Icon offset="1px" color={config.colors.c3} name={option.icon} />
        <P1 color={config.colors.c2}>{option.name}</P1>
      </Stack>
    </NoltButtonWrapper>
  );
};

const NoltButtonWrapper = ({ isNoltButton, children }) => {
  if (!isNoltButton) return children;

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a data-nolt="button" id="nolt-button" href="" style={{ textShadow: "none" }}>
      {children}
    </a>
  );
};

const DropDownHeader = ({ children }) => {
  const config = useConfig();
  return (
    <Box
      w="calc( 100% + 20px)"
      padding="8px"
      margin="-8px -10px 12px"
      background={"rgba(0,0,0,0.02)"}
      borderBottom={`1px solid ${config.colors.uiBorderColor}`}
    >
      {children}
    </Box>
  );
};

const DropDownFooter = ({ children }) => {
  const config = useConfig();

  return (
    <Box
      w="calc( 100% + 20px)"
      padding="8px"
      margin="4px -10px -8px"
      background={"rgba(0,0,0,0.03)"}
      borderTop={`1px solid ${config.colors.uiBorderColor}`}
    >
      {children}
    </Box>
  );
};

const MacInstallModal = ({ show, setShow }) => {
  return (
    <Modal width="480px" show={show} setShow={setShow}>
      <H2 mb={3}>Install Mailbrew</H2>
      <P1>
        Open Google Chrome and click this same <Icon name="arrowDownBold" offset="-4px" /> button to install our
        progressive web-app.
      </P1>
      <Box my={6} as="img" src={dockImg} w="100%" />
      <Stack w="100%" align="right">
        <Button px={8} onClick={() => setShow(false)}>
          Got it
        </Button>
      </Stack>
    </Modal>
  );
};

const IOsInstallModal = ({ show, setShow }) => {
  return (
    <Modal bottomSheet show={show} setShow={setShow}>
      <Stack align="center" position="absolute" top={-21} left="0" right="0">
        <Box
          as={motion.img}
          initial={false}
          animate={show ? { y: 0 } : { y: 80 }}
          transition={{ type: "spring", duration: 1.1, bounce: 0.08 }}
          my={4}
          src={IOsIcon}
          w="130px"
          boxShadow={`0px 10px 60px -10px rgba(0,0,0,0.15)`}
          borderRadius="34px"
        />
      </Stack>
      <H3 align="center" mt={16} mb={3}>
        How to install
      </H3>
      <P1 mb={4} align="center">
        To install on your iPhone, tap the share icon and then add to your homescreen.
      </P1>
      <Stack align="center" w="100%">
        <Icon name="arrowDown" />
      </Stack>
    </Modal>
  );
};

export default UserMenu;
