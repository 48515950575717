export default function trimText(text, chars) {
  if (!text) {
    return null;
  }
  if (text.length + 1 > chars) {
    return text.slice(0, chars) + "...";
  } else {
    return text;
  }
}
