import { Box } from "@mailbrew/uikit";
import { useSelector } from "react-redux";
import { authUserSelector } from "reducers/authReducer";
import UserAvatar from "./UserAvatar";

const AvatarWithBadge = ({ ...otherProps }) => {
  const user = useSelector(authUserSelector);
  const size = "54px";

  if (!user?.profile?.image_url) {
    return null;
  }

  return (
    <Box {...otherProps}>
      <Box w={size} h={size} position="relative">
        <UserAvatar user={user} size={size} />
      </Box>
    </Box>
  );
};

export default AvatarWithBadge;
