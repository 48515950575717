import { forwardRef } from "react";

const UnstyledA = forwardRef(({ style, children, ...props }, ref) => {
  return (
    <a
      style={{ cursor: "pointer", textDecoration: "none", borderBottom: "none", fontWeight: "inherit", ...style }}
      ref={ref}
      {...props}
    >
      {children}
    </a>
  );
});

export default UnstyledA;
