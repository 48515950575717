import { AnimatedContainer, Card, H2, P1, Spacer, Stack, useBreakpoint, useConfig } from "@mailbrew/uikit";
import { sourcesData } from "data/sourcesData";
import BrewsGlyph from "../images/glyphs/Brews.svg";
import InboxGlyph from "../images/glyphs/Newsletters.svg";
import SavedGlyph from "../images/glyphs/Saved.svg";
import { MailbrewLogo } from "./brand/MailbrewLogo";

const EmptyStateCard = ({ title, description, showLogo, glyph, thumb, children, ...otherProps }) => {
  return (
    <NoMobileCard animated w="600px" maxW="100%" px={9} py={7} mb="5vh" overflow="hidden" {...otherProps}>
      <AnimatedContainer flex ai="center" fd="column">
        <FeatureTitleAndIcon title={title} description={description} showLogo={showLogo} glyph={glyph} thumb={thumb} />
        <Spacer size={children ? 5 : 2} />
        {children}
      </AnimatedContainer>
    </NoMobileCard>
  );
};

export const FeatureTitleAndIcon = ({ title, description, showLogo, glyph, thumb: providedThumb }) => {
  const config = useConfig();
  const Thumb = providedThumb && sourcesData[providedThumb].thumb;
  const Glyph = (props) => {
    if (glyph === "brews") {
      return <BrewsGlyph {...props} />;
    }
    if (glyph === "inbox") {
      return <InboxGlyph {...props} />;
    }
    if (glyph === "later") {
      return <SavedGlyph {...props} />;
    }
    return null;
  };
  return (
    <Stack vertical align="center" gap={0} mt={showLogo ? -2 : 0}>
      {glyph && <Glyph fill={config.colors.accent1} width="54px" height="54px" />}
      {showLogo && <MailbrewLogo size="54px" label={false} />}
      {providedThumb && <Thumb width="44px" height="44px" />}
      {(title || description) && <Spacer size={2} />}
      <H2 align="center" mb={1}>
        {title}
      </H2>
      <P1 align="center" dangerouslySetInnerHTML={{ __html: description }} />
    </Stack>
  );
};

const NoMobileCard = ({ children, animated, ...otherProps }) => {
  const breakpointHit = useBreakpoint();

  if (breakpointHit) {
    return children;
  } else {
    return (
      <Card animated={animated} {...otherProps}>
        {children}
      </Card>
    );
  }
};

export default EmptyStateCard;
