import {
  fetchRedditSuggestions,
  searchPodcasts,
  searchRssFeeds,
  searchStock,
  searchTwitterUser,
  searchWeatherLocation,
  searchYouTubeChannels,
} from "utils/sourceSuggestionFetchers";
import BetaListGlyph from "../images/icons/sources/betaListGlyph.svg";
import BetaListThumb from "../images/icons/sources/betaListThumb.svg";
import CalendarGlyph from "../images/icons/sources/calendarGlyph.svg";
import CalendarThumb from "../images/icons/sources/calendarThumb.svg";
import CovidGlyph from "../images/icons/sources/covidGlyph.svg";
import CovidThumb from "../images/icons/sources/covidThumb.svg";
import CryptoGlyph from "../images/icons/sources/cryptoGlyph.svg";
import CryptoThumb from "../images/icons/sources/cryptoThumb.svg";
import DevToGlyph from "../images/icons/sources/DevToGlyph.svg";
import DevToThumb from "../images/icons/sources/DevToThumb.svg";
import ExchangeRateGlyph from "../images/icons/sources/exchangeRateGlyph.svg";
import ExchangeRateThumb from "../images/icons/sources/exchangeRateThumb.svg";
import GoogleNewsGlyph from "../images/icons/sources/googleNewsGlyph.svg";
import GoogleNewsThumb from "../images/icons/sources/googleNewsThumb.svg";
import HackerNewsGlyph from "../images/icons/sources/hackerNewsGlyph.svg";
import HackerNewsThumb from "../images/icons/sources/hackerNewsThumb.svg";
import InboxGlyph from "../images/icons/sources/inboxGlyph.svg";
import InboxThumb from "../images/icons/sources/inboxThumb.svg";
import PodcastGlyph from "../images/icons/sources/podcastGlyph.svg";
import PodcastThumb from "../images/icons/sources/podcastThumb.svg";
import ProductHuntGlyph from "../images/icons/sources/productHuntGlyph.svg";
import ProductHuntThumb from "../images/icons/sources/productHuntThumb.svg";
import ReadLaterGlyph from "../images/icons/sources/readLaterGlyph.svg";
import ReadLaterThumb from "../images/icons/sources/readLaterThumb.svg";
import ReadwiseGlyph from "../images/icons/sources/readwiseGlyph.svg";
import ReadwiseThumb from "../images/icons/sources/readwiseThumb.svg";
import RedditGlyph from "../images/icons/sources/redditGlyph.svg";
import RedditThumb from "../images/icons/sources/redditThumb.svg";
import RssGlyph from "../images/icons/sources/rssGlyph.svg";
import RssThumb from "../images/icons/sources/rssThumb.svg";
import SeparatorGlyph from "../images/icons/sources/separatorGlyph.svg";
import SeparatorThumb from "../images/icons/sources/separatorThumb.svg";
import StocksGlyph from "../images/icons/sources/stocksGlyph.svg";
import StocksThumb from "../images/icons/sources/stocksThumb.svg";
import TextGlyph from "../images/icons/sources/textGlyph.svg";
import TextThumb from "../images/icons/sources/textThumb.svg";
import TodayGlyph from "../images/icons/sources/todayGlyph.svg";
import TodayThumb from "../images/icons/sources/todayThumb.svg";
import TwitterGlyph from "../images/icons/sources/twitterGlyph.svg";
import TwitterLikesGlyph from "../images/icons/sources/twitterLikesGlyph.svg";
import TwitterLikesThumb from "../images/icons/sources/twitterLikesThumb.svg";
import TwitterListGlyph from "../images/icons/sources/twitterListGlyph.svg";
import TwitterListThumb from "../images/icons/sources/twitterListThumb.svg";
import TwitterSearchGlyph from "../images/icons/sources/twitterSearchGlyph.svg";
import TwitterSearchThumb from "../images/icons/sources/twitterSearchThumb.svg";
import TwitterThumb from "../images/icons/sources/twitterThumb.svg";
import TwitterTopLinksGlyph from "../images/icons/sources/twitterTopLinksGlyph.svg";
import TwitterTopLinksThumb from "../images/icons/sources/twitterTopLinksThumb.svg";
import WeatherGlyph from "../images/icons/sources/weatherGlyph.svg";
import WeatherThumb from "../images/icons/sources/weatherThumb.svg";
import YouTubeGlyph from "../images/icons/sources/youTubePlaylistGlyph.svg";
import YouTubeThumb from "../images/icons/sources/youTubePlaylistThumb.svg";

export const sourcesData = {
  weather: {
    name: "Weather",
    keywords: "weather meteo",
    thumb: WeatherThumb,
    glyph: WeatherGlyph,
    color: "#FD975D",
    opensEditor: true,
    isValid: (source) => true,
    inlineSearch: {
      fetcher: searchWeatherLocation,
      placeholders: ["Search city..."],
    },
  },
  covid: {
    name: "Covid-19",
    keywords: "coronavirus",
    thumb: CovidThumb,
    glyph: CovidGlyph,
    color: "#679379",
    opensEditor: true,
    isValid: (source) => true,
  },
  subreddit: {
    name: "Subreddit",
    thumb: RedditThumb,
    glyph: RedditGlyph,
    color: "#FF4500",
    opensEditor: true,
    isValid: (source) => source.subreddit && source.subreddit.length > 0,
    inlineSearch: {
      fetcher: fetchRedditSuggestions,
      placeholders: ["Subreddit..."],
    },
  },
  rss_feed: {
    name: "Websites & RSS",
    keywords: "feeds",
    thumb: RssThumb,
    glyph: RssGlyph,
    color: "#A161F3",
    opensEditor: true,
    sharable: true,
    isValid: (source) => source.title?.length > 0 && source.url?.length > 0,
    inlineSearch: {
      fetcher: searchRssFeeds,
      placeholders: [
        "Daring Fireball or daringfireball.net",
        "The Verge or theverge.com",
        "TechCrunch or techcrunch.com",
      ],
    },
  },
  twitter_user: {
    name: "Twitter User",
    shortName: "Single User",
    thumb: TwitterThumb,
    glyph: TwitterGlyph,
    color: "#1DA1F2",
    opensEditor: true,
    sharable: true,
    isValid: (source) => source?.screen_name?.length > 0,
    inlineSearch: {
      fetcher: searchTwitterUser,
      placeholders: [
        "naval or Naval",
        "linuz90 or Fabrizio Rinaldi",
        "frankdilo or Francesco Di Lorenzo",
        "mailbrew or Mailbrew",
        "typefullyapp or Typefully",
      ],
    },
  },
  twitter_search: {
    name: "Twitter Search",
    shortName: "Search",
    additionalInfo: "Great to monitor social mentions, topics, accounts, and hashtags.",
    thumb: TwitterSearchThumb,
    glyph: TwitterSearchGlyph,
    color: "#1DA1F2",
    opensEditor: true,
    isValid: (source) => source?.query?.length > 0,
  },
  twitter_list: {
    name: "Twitter Lists",
    shortName: "Lists",
    thumb: TwitterListThumb,
    glyph: TwitterListGlyph,
    color: "#1DA1F2",
    opensEditor: true,
    isValid: (source) => !!source?.list_id,
  },
  twitter_timeline: {
    name: "Twitter Timeline",
    shortName: "Timeline",
    additionalInfo: "Get the most popular tweets from the accounts you follow.",
    thumb: TwitterListThumb,
    glyph: TwitterListGlyph,
    color: "#1DA1F2",
    opensEditor: true,
    isValid: (source) => true,
  },
  twitter_top_links: {
    name: "Twitter Top Links",
    shortName: "Top Links",
    thumb: TwitterTopLinksThumb,
    glyph: TwitterTopLinksGlyph,
    color: "#1DA1F2",
    opensEditor: true,
    isValid: (source) => true,
  },
  twitter_likes: {
    name: "Twitter Likes By",
    shortName: "Liked By",
    additionalInfo:
      "Discover what tweets, articles, or images your favorite people liked. It works with any Twitter list.",
    thumb: TwitterLikesThumb,
    glyph: TwitterLikesGlyph,
    color: "#1DA1F2",
    opensEditor: true,
    isValid: (source) => !!source?.list_id,
  },
  hacker_news: {
    name: "Hacker News",
    keywords: "technology hacker news",
    thumb: HackerNewsThumb,
    glyph: HackerNewsGlyph,
    color: "#FF6600",
    opensEditor: false,
    isValid: (source) => true,
  },
  youtube_playlist: {
    name: "YouTube",
    keywords: "video",
    thumb: YouTubeThumb,
    glyph: YouTubeGlyph,
    color: "#FF0000",
    opensEditor: true,
    sharable: true,
    isValid: (source) => source.playlist_id?.length > 0,
    inlineSearch: {
      fetcher: searchYouTubeChannels,
      placeholders: [
        "Marques Brownlee",
        "The Verge",
        "CNET",
        "Austin Evans",
        "Google Tech Talks",
        "Unbox Therapy",
        "TED",
        "Vsauce",
        "Linus Tech Tips",
        "NASA",
        "Jonathan Morrison",
        "Lessons from the screenplay",
      ],
    },
  },
  youtube_channel: {
    name: "YouTube",
    thumb: YouTubeThumb,
    glyph: YouTubeGlyph,
    color: "#FF0000",
    opensEditor: true,
    isValid: (source) => source.title?.length > 0 && source?.channel_url.length > 0,
    notInModal: true,
  },
  podcast: {
    name: "Podcast",
    thumb: PodcastThumb,
    glyph: PodcastGlyph,
    color: "#E65B9E",
    opensEditor: true,
    isValid: (source) => source.podcasts.length > 0,
    inlineSearch: {
      fetcher: searchPodcasts,
      placeholders: ["The Daily"],
    },
  },
  google_news: {
    name: "Google News",
    thumb: GoogleNewsThumb,
    glyph: GoogleNewsGlyph,
    color: "#5383EC",
    opensEditor: true,
    isValid: (source) =>
      source.mode === "query" ? source.query?.length > 0 : source.mode === "topic" ? source.url?.length > 0 : false,
  },
  exchange_rate: {
    name: "Exchange Rate",
    keywords: "money currency fiat",
    thumb: ExchangeRateThumb,
    glyph: ExchangeRateGlyph,
    color: "#5D7587",
    opensEditor: true,
    isValid: (source) => true,
  },
  stocks: {
    name: "Stocks",
    keywords: "stonks money",
    thumb: StocksThumb,
    glyph: StocksGlyph,
    color: "#539384",
    opensEditor: true,
    isValid: (source) => source.stocks?.length > 0,
    inlineSearch: {
      fetcher: searchStock,
      placeholders: ["AAPL", "TSLA", "SQ", "AMZN"],
    },
  },
  crypto: {
    name: "Crypto",
    keywords: "bitcoin ethereum hodl hold money moon",
    thumb: CryptoThumb,
    glyph: CryptoGlyph,
    color: "#E0AF13",
    opensEditor: true,
    isValid: (source) => source.assets?.length > 0,
  },
  today: {
    name: "Today's Date",
    keywords: "calendar",
    thumb: TodayThumb,
    glyph: TodayGlyph,
    color: "#757C95",
    opensEditor: false,
    isValid: (source) => true,
  },
  text: {
    name: "Text",
    keywords: "intro paragraph custom markdown",
    thumb: TextThumb,
    glyph: TextGlyph,
    color: "#757C95",
    opensEditor: true,
    isValid: (source) => source.text.length > 0,
  },
  separator: {
    name: "Separator",
    thumb: SeparatorThumb,
    glyph: SeparatorGlyph,
    color: "#757C95",
    opensEditor: true,
    isValid: (source) => true,
  },
  inbox: {
    name: "Newsletters",
    additionalInfo: "Receive newsletters in your next Mailbrew digest, instead of cluttering your inbox.",
    keywords: "inbox",
    thumb: InboxThumb,
    glyph: InboxGlyph,
    color: "#605DF7",
    opensEditor: true,
    isValid: (source) => true,
    title: "Inbox",
  },
  devto: {
    name: "Dev.to",
    keywords: "programming development javascript react code",
    thumb: DevToThumb,
    glyph: DevToGlyph,
    color: "#08090A",
    opensEditor: false,
    isValid: (source) => true,
  },
  product_hunt: {
    name: "Product Hunt",
    keywords: "startups products makers hackers",
    thumb: ProductHuntThumb,
    glyph: ProductHuntGlyph,
    color: "#CC4D29",
    opensEditor: false,
    isValid: (source) => true,
  },
  betalist: {
    name: "BetaList",
    keywords: "startups products makers hackers",
    thumb: BetaListThumb,
    glyph: BetaListGlyph,
    color: "#000000",
    opensEditor: false,
    isValid: (source) => true,
  },
  read_later: {
    name: "Saved",
    additionalInfo: "Save links for later. We'll add them to your next digest, and you'll be able to read them online.",
    keywords: "read later pocket instapaper",
    thumb: ReadLaterThumb,
    glyph: ReadLaterGlyph,
    color: "#3A3A3A",
    opensEditor: true,
    isValid: (source) => true,
  },
  readwise: {
    name: "Readwise",
    additionalInfo: "Get your highlights in your digest. You'll need a Readwise account.",
    keywords: "bookmarks highlights",
    thumb: ReadwiseThumb,
    glyph: ReadwiseGlyph,
    color: "#000000",
    opensEditor: true,
    isValid: (source) => true,
  },
  calendar: {
    name: "Calendar",
    shortName: "Your events",
    keywords: "google events meeting zoom",
    thumb: CalendarThumb,
    glyph: CalendarGlyph,
    color: "#5383EC",
    opensEditor: true,
    isValid: (source) => true,
  },
};
