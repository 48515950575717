import { useBreakpoint, useConfig } from "@mailbrew/uikit";
import TitleDivider from "components/TitleDivider";
import { Fragment, useMemo, useState } from "react";
import recencySplitter from "utils/recencySplitter";

/**
 * This component creates the sectioned structure found in brews / inbox / later pages.
 */
const RecencySections = (props) => {
  const { items, dateKey, renderItem, showTitlesImmediately, itemsWrapperComponent: ItemsWrapperComponent } = props;
  const config = useConfig();
  const breakpointHit = useBreakpoint();

  const [showTitles, setShowTitles] = useState(showTitlesImmediately ? true : false);

  const sections = useMemo(() => {
    return items?.length > 0 ? recencySplitter(items, dateKey) : [];
  }, [dateKey, items]);

  let itemIndex = -1;

  return (
    <Fragment>
      {sections.map((section, index) => {
        const isFirst = index === 0;
        const justOld = sections.length < 2 && section.label === "Older";
        return (
          <Fragment key={section.label}>
            {showTitles && !justOld && !isFirst && (
              <TitleDivider
                my={breakpointHit ? 7 : 9}
                title={section.label}
                px={config.layout.padding}
                color={config.colors.c3}
              />
            )}
            {ItemsWrapperComponent ? (
              <ItemsWrapperComponent>
                {section.items.map((item) => {
                  itemIndex += 1;
                  return renderItem({ item, itemIndex, showTitlesCallback: () => setShowTitles(true) });
                })}
              </ItemsWrapperComponent>
            ) : (
              section.items.map((item, index) => {
                itemIndex += 1;
                const isFirstOfSection = index === 0;
                const isLastOfSection = index === section.items.length - 1;
                return renderItem({
                  item,
                  itemIndex,
                  isFirstOfSection,
                  isLastOfSection,
                  showTitlesCallback: () => setShowTitles(true),
                });
              })
            )}
          </Fragment>
        );
      })}
    </Fragment>
  );
};

export default RecencySections;
