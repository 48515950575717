import { Button, useBreakpoint, useConfig } from "@mailbrew/uikit";
import useBiggerTouchButtonsStyle from "hooks/useBiggerTouchButtonsStyle";

export default function SmallSecondaryButton({ children, noLabelOnMobile = false, style, ...otherProps }) {
  const config = useConfig();
  const hit = useBreakpoint(640);
  const btnStyle = useBiggerTouchButtonsStyle();

  return (
    <Button
      height="24px"
      color={config.colors.c2}
      variant={["pill", "secondary"]}
      style={{ ...btnStyle, ...style }}
      {...otherProps}
    >
      {(!hit || !noLabelOnMobile) && children}
    </Button>
  );
}

export const BIGGER_BUTTONS_BREAKPOINT = 480;
