import { HStack, Spinner, useBreakpoint } from "@mailbrew/uikit";
import useDelayedValue from "hooks/useDelayedValue";
import { Fragment, useEffect, useState } from "react";
import VisibilitySensor from "react-visibility-sensor";

export const LoadMore = ({ isLoadingMore, canLoadMore, loadMore, vertical = true }) => {
  const [delayedLoading, setDelayedLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const hit = useBreakpoint(480);

  useEffect(() => {
    let timeout = null;
    if (isLoadingMore) {
      timeout = setTimeout(() => {
        setDelayedLoading(true);
      }, 2500);
      return () => clearTimeout(timeout);
    } else {
      clearTimeout(timeout);
      setDelayedLoading(false);
    }
  }, [isLoadingMore]);

  const delayedLoadingMore = useDelayedValue(isLoadingMore, 100, true);

  useEffect(() => {
    if (isVisible && !delayedLoadingMore && canLoadMore) {
      loadMore();
    }
  }, [canLoadMore, delayedLoadingMore, isVisible, loadMore]);

  return (
    <Fragment>
      {canLoadMore && (
        <VisibilitySensor
          onChange={(isVisible) => {
            setIsVisible(isVisible);
          }}
          partialVisibility
        >
          {/* This section can't have display: none because it breaks the VisibilitySensor. */}
          <div
            style={{
              position: "relative",
              ...(vertical
                ? {
                    bottom: parseInt(window.innerHeight * (hit ? 1.5 : 0.6)) + "px",
                  }
                : {
                    right: parseInt(window.innerWidth * (hit ? 1.5 : 0.6)) + "px",
                  }),
              width: "1px",
              height: "1px",
            }}
          />
        </VisibilitySensor>
      )}
      <HStack align="center" w="100%" pt={3} pb={2} style={{ flex: 1 }}>
        {delayedLoading && <Spinner size={25} />}
      </HStack>
    </Fragment>
  );
};
