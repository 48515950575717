import {
  Box,
  notif,
  NotifWithButton,
  ToolbarButton,
  useBreakpoint,
  useConfig,
  useDetectBrowser,
} from "@mailbrew/uikit";
import api from "dependencies/api";
import { motion } from "framer-motion";
import { useRouter } from "next/router";
import { Fragment, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactDOM from "react-dom";
import urls from "urls";
import createTwitterLink from "utils/createTwitterLink";

export const ModalToolbar = ({ children, width = 340, mt, mb, show = true, style, ...props }) => {
  const config = useConfig();
  const hit = useBreakpoint(480);

  const cleanChildren =
    Array.isArray(children) && typeof children.map !== "undefined" ? children.filter((child) => child) : children;

  return (
    <Fragment>
      <Box mt={mt} style={{ height: "30px" }} />
      {ReactDOM.createPortal(
        <Box
          as={motion.div}
          bg={config.colors.bg0}
          w={hit ? "100%" : width + "px"}
          maxW="100%"
          shadow={hit ? "none" : config.effects.smallShadow}
          borderTop={`1px solid ${config.colors.uiBorderColor}`}
          borderLeft={!hit && `1px solid ${config.colors.uiBorderColor}`}
          borderRight={!hit && `1px solid ${config.colors.uiBorderColor}`}
          borderBottom={!hit && `1px solid ${config.colors.uiBorderColor}`}
          radius={hit ? 0 : "12px"}
          padding={hit ? `0 ${config.layout.padding} env(safe-area-inset-bottom)` : `2px ${config.layout.padding}`}
          style={{
            left: hit ? 0 : `calc(50% - ${width / 2}px)`,
            bottom: hit ? 0 : `calc( env(safe-area-inset-bottom) + ${config.layout.padding})`,
            position: "fixed",
            boxSizing: "border-box",
            zIndex: "99",
            ...style,
          }}
          animate={{
            y: show ? 0 : "140%",
          }}
          initial={{
            y: "140%",
          }}
          transition={{ type: "spring", duration: 0.8, bounce: 0.1 }}
          flex
          ai="center"
          jc="space-between"
        >
          {cleanChildren}
        </Box>,
        document.body
      )}
    </Fragment>
  );
};

export const ToolbarTweetButton = ({ title, url, onClick }) => {
  const twitterLink = createTwitterLink({ text: title, url: url });

  if (onClick) {
    return (
      <ToolbarButton icon="twitter" onClick={onClick}>
        Tweet
      </ToolbarButton>
    );
  } else {
    return (
      <a href={twitterLink} target="_blank" rel="noopener noreferrer">
        <ToolbarButton icon="twitter">Tweet</ToolbarButton>
      </a>
    );
  }
};

export const ToolbarShareButton = ({ url, onClick, ...otherProps }) => {
  const { hasTouch } = useDetectBrowser();

  if ((navigator.share && hasTouch) || onClick) {
    return (
      <ToolbarButton
        icon="shareAlt"
        {...otherProps}
        onClick={() => {
          if (onClick) {
            onClick();
          } else {
            try {
              navigator.share({
                url,
              });
            } catch (err) {
              // navigator.share throws if the user cancels sharing
            }
          }
        }}
      >
        Share
      </ToolbarButton>
    );
  } else {
    return (
      <CopyToClipboard text={url} onCopy={() => notif.success("Copied")} style={{ lineHeight: 0, cursor: "pointer" }}>
        <ToolbarButton icon="shareAlt" {...otherProps}>
          Share
        </ToolbarButton>
      </CopyToClipboard>
    );
  }
};

export const ToolbarReadLaterButton = (props) => {
  const { url, ...otherProps } = props;
  const router = useRouter();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleAddURL = async () => {
    try {
      setLoading(true);
      await api.post("/add_read_later_item_via_url/", { url });
    } catch (err) {
      const message = err?.response?.data?.detail;
      notif.error(message ? "Oops, there was an error fetching your link. Please retry." : "There was a problem.");
    } finally {
      setLoading(false);
      setSuccess(true);
      notif.success(<NotifWithButton msg="Saved" action="View" onClick={() => router.push(urls.saved())} />);
    }
  };

  return (
    <ToolbarButton
      loading={loading}
      icon={success ? "checkmarkBold" : "bookmark"}
      {...otherProps}
      onClick={handleAddURL}
    >
      {success ? "Saved" : "Later"}
    </ToolbarButton>
  );
};

export const ToolbarCloseButton = ({ onClick }) => {
  return (
    <ToolbarButton icon="arrowDown" onClick={onClick}>
      Close
    </ToolbarButton>
  );
};

export const ToolbarArchiveButton = ({ onClick }) => {
  return (
    <ToolbarButton icon="archiveBold" onClick={onClick}>
      Archive
    </ToolbarButton>
  );
};
