import { Divider, H2, H3, Icon, P2, Stack, useBreakpoint, useConfig } from "@mailbrew/uikit";

const TitleDivider = (props) => {
  const breakpointHit = useBreakpoint();
  const config = useConfig();

  const { title, color, icon, small, bold = true, responsive, ...otherProps } = props;

  if (title) {
    if (responsive && breakpointHit) {
      return (
        <Stack noWrap gap={0} align="center">
          {icon && <Icon mr={1} color={config.colors.c1} name={icon} size={20} offset="-1px" />}
          <H2 mt={5} mb={4}>
            {title}
          </H2>
        </Stack>
      );
    } else {
      return (
        <Stack gap={3} noWrap width="100%" align="stretch" mt={small ? 4 : 6} mb={small ? 4 : 6} {...otherProps}>
          <Divider style={{ flex: 1 }} size={0} />
          {!small ? (
            <Stack noWrap gap={0} align="center">
              {icon && <Icon mr={1} color={color ?? config.colors.c1} name={icon} size={18} offset="-1px" />}
              <H3 color={color} small bold={bold} style={{ flex: "0 1 auto" }}>
                {title}
              </H3>
            </Stack>
          ) : (
            <Stack noWrap gap={0} align="center">
              {icon && <Icon mr={1} color={color ?? config.colors.c3} name={icon} size={15} />}
              <P2 color={color ?? config.colors.c3} style={{ flex: "0 1 auto" }}>
                {title}
              </P2>
            </Stack>
          )}
          <Divider style={{ flex: 1 }} size={0} />
        </Stack>
      );
    }
  } else {
    return <Divider size={small ? 4 : 8} width="100%" {...otherProps} />;
  }
};

export default TitleDivider;
