import {
  Box,
  Button,
  Card,
  CardHeader,
  Expandable,
  getColorFromCssVariable,
  Grid,
  H2,
  H3,
  Icon,
  P1,
  P2,
  ProgressBar,
  Spacer,
  Stack,
  SubtleCard,
  useBreakpoint,
  useConfig,
} from "@mailbrew/uikit";
import Highlight from "components/Highlight";
import useStripeCheckout from "hooks/useStripeCheckout";
import { useRouter } from "next/router";
import { Fragment } from "react";
import { useDispatch } from "react-redux";
import { reactivateSubscription } from "reducers/monetizationReducer";
import useSWR from "swr";
import tinycolor from "tinycolor2";
import urls from "urls";
import clamp from "utils/clamp";
import { fromNow } from "utils/formatDate";
import { pluralize } from "utils/pluralize";
import AlbertoGiuntaImage from "../images/avatars/AlbertoGiunta.jpg";
import BrianLovinImage from "../images/avatars/BrianLovin.jpg";
import DHHImage from "../images/avatars/DHH.jpg";
import FabrizioRinaldiImage from "../images/avatars/FabrizioRinaldi.png";
import FrancescoDiLorenzoImage from "../images/avatars/FrancescoDiLorenzo.jpg";
import JanelImage from "../images/avatars/Janel.jpg";
import ViticciImage from "../images/avatars/Viticci.jpg";
import AvatarWithBadge from "./AvatarWithBadge";
import { MailbrewLogo } from "./brand/MailbrewLogo";
import ConfettiEffect from "./ConfettiEffect";
import ExternalLink from "./ExternalLink";
import { usePaywallState } from "./PaywallStateProvider";
import PostItNote from "./PostItNote";
import StripeProvider from "./StripeProvider";
import StyledLink from "./StyledLink";
import SubstackPaywall from "./SubstackPaywall";
import TitleDivider from "./TitleDivider";

export default function Paywall({ inModal }) {
  const { user, monetization } = usePaywallState();

  const status = monetization?.status;

  const uiState = (() => {
    if (status === "trialing_cc") return "trialing_cc";
    if (["subscribed", "free_access", "past_due", "canceled_with_access"].includes(status)) return "subscribed";
    else return "not_subscribed";
  })();

  return (
    <Box mb={inModal ? 2 : 12} w={"100%"}>
      <GradientWrapper inModal={inModal} maxWidth={"100%"}>
        {uiState === "trialing_cc" && <HeaderTrialingCC />}
        {uiState === "subscribed" && <HeaderSubscribed />}
        {uiState === "not_subscribed" && <HeaderNotSubscribed />}
      </GradientWrapper>
      {uiState === "not_subscribed" && (
        <>
          {!user && (
            <H3 mt={2} align="center">
              Pricing
            </H3>
          )}
          <Stack vertical align="center" mt={8}>
            <StripeProvider>
              <Pricing />
            </StripeProvider>
          </Stack>
          <Stack mt={inModal ? -2 : 4} mb={4} vertical gap={0} w={inModal ? "800px" : "100%"} maxW="100%" mx="auto">
            <Testimonials />
            <AboutUs />
          </Stack>
        </>
      )}
    </Box>
  );
}

const HeaderTrialingCC = () => {
  const config = useConfig();

  const { justUpgraded } = usePaywallState();

  return (
    <>
      {justUpgraded && <ConfettiEffect />}
      <Stack vertical align="center" gap={0} maxW="100%">
        <AvatarWithBadge mb={4} />
        <H2 align="center" mb={justUpgraded ? 2 : 0}>
          {justUpgraded ? "Trial started" : "You're trialing"}
        </H2>
        <P1 color={config.colors.c1} align="center" maxWidth="400px" w="100%" mt={2}>
          You can now try all our features, and see how they improve your life.
        </P1>
        {justUpgraded && <KeepBrewing />}
        {justUpgraded && <NextBilling />}
      </Stack>
    </>
  );
};

const HeaderSubscribed = () => {
  const config = useConfig();
  const { monetization, justUpgraded } = usePaywallState();

  const monetizationStatus = monetization?.status;

  return (
    <Stack vertical align="center" gap={2} maxW="100%">
      {["subscribed", "free_access"].includes(monetizationStatus) && (
        <>
          {justUpgraded && <ConfettiEffect />}
          <AvatarWithBadge mb={2} />
          <H2 align="center" my={2}>
            Subscribed 🔥
          </H2>
          <P1 color={config.colors.c1} align="center" maxWidth="400px" w="100%">
            Thanks for subscribing. It's thanks to people like you that we can work on Mailbrew every day.
          </P1>
          {justUpgraded && <KeepBrewing />}
        </>
      )}
      {monetizationStatus === "canceled_with_access" && (
        <>
          <AvatarWithBadge mb={2} />
          <H2 align="center">You canceled your plan</H2>
          <ReactivatePlanCta />
        </>
      )}
      {monetizationStatus === "past_due" && (
        <>
          <AvatarWithBadge mb={2} />
          <H2 align="center">⚠️ Fix your payment method</H2>
          <FixBillingCta />
        </>
      )}
    </Stack>
  );
};

const HeaderNotSubscribed = () => {
  const { user, monetization } = usePaywallState();

  const wasFree = user?.was_free;
  const monetizationStatus = monetization?.status;
  const { days_left: daysLeft, duration: trialDuration } = user?.monetization?.data || {};

  return (
    <Stack align="center" gap={1} vertical w="100%">
      <MailbrewLogo label={false} size="56px" />
      {(() => {
        if (!user) return null;
        if (monetizationStatus === "trialing") return <H3 align="center">You're trying Mailbrew</H3>;
        return <H3 align="center">Upgrade to keep using Mailbrew</H3>;
      })()}

      {wasFree && (
        <>
          <Spacer />
          <WasFreeStickyNote />
        </>
      )}

      {!wasFree && ["trialing", "trial_ended"].includes(monetizationStatus) && (
        <TrialProgress daysLeft={daysLeft} trialDuration={trialDuration} mt={3} />
      )}
    </Stack>
  );
};

const WasFreeStickyNote = () => {
  return (
    <PostItNote title="We discontinued our free plan" width="500px">
      <P1>
        As a small team with limited resources, we want to focus on making Mailbrew the best it can be. We believe
        asking all users to pay a new affordable price is the best path forward.
      </P1>
    </PostItNote>
  );
};

const Pricing = () => {
  const { coupon, products, monetizationStatus } = usePaywallState();
  const router = useRouter();

  const { handleUpgrade, upgradeLoading } = useStripeCheckout({
    freeTrialEnabled: true,
    handlesCompletion: true,
    redirectPath: router.asPath,
  });

  const handleSubscribeToPlan = (productId, periodicity) => {
    const priceId = products[productId].plans[periodicity + "ly"].price_id;
    handleUpgrade({ priceId, coupon });
  };

  return (
    <Fragment>
      <Expandable expanded={coupon}>{coupon && <CouponCard coupon={coupon} />}</Expandable>
      <SubstackPaywall
        onSubscribeToPlan={handleSubscribeToPlan}
        loading={upgradeLoading}
        currentPlan={monetizationStatus === "trialing" ? "monthly" : null}
      />
    </Fragment>
  );
};

const FixBillingCta = () => {
  return (
    <Fragment>
      <P1 align="center" w="100%" maxW="400px" mb={3} mt={3}>
        We couldn't charge your card. Please update your card to keep received your digests.
      </P1>
      <StyledLink to={urls.settings_billing()}>Fix payment method</StyledLink>
    </Fragment>
  );
};

const ReactivatePlanCta = () => {
  const dispatch = useDispatch();
  const { config: userConfig } = usePaywallState();

  const isFreemium = userConfig?.freemium;

  return (
    <Fragment>
      <P1 align="center" w="100%" maxW="400px" mb={3} mt={2}>
        {isFreemium ? (
          <>
            You'll soon be downgraded to our free plan. Please, reactive your plan to keep access to all pro features.
          </>
        ) : (
          <>We'll stop sending your brews soon, please reactive your plan if you wish to keep receiving them.</>
        )}
      </P1>
      <Button icon="refresh" onClick={() => dispatch(reactivateSubscription())}>
        Reactivate plan
      </Button>
    </Fragment>
  );
};

const NextBilling = () => {
  const { data: nextPaymentInfo } = useSWR("/upcoming_invoice/?customer=true");

  if (!nextPaymentInfo) return null;

  return (
    <P2 mt={4} align="center">
      You'll be automatically billed when the trial ends {fromNow(nextPaymentInfo.next_payment_attempt * 1000)}.
    </P2>
  );
};

const KeepBrewing = () => {
  const { paywallModalShown, setPaywallModalShown } = usePaywallState();

  const props = {
    variant: "white",
    icon: "coffee",
    mt: 5,
    mb: 2,
    width: "200px",
    maxW: "100%",
  };

  return (
    <Fragment>
      {paywallModalShown ? (
        <Button {...props} onClick={() => setPaywallModalShown(false)}>
          Keep Brewing
        </Button>
      ) : (
        <StyledLink {...props} to={urls.brews()}>
          Keep Brewing
        </StyledLink>
      )}
    </Fragment>
  );
};

const CouponCard = ({ coupon }) => {
  const config = useConfig();

  let explanation;

  if (coupon.duration === "forever") {
    explanation = <>{coupon.percent_off}% off forever.</>;
  } else if (coupon.duration_in_months === 12) {
    // no need for month/year distinction
    explanation = <>{coupon.percent_off}% off for the first year.</>;
  } else {
    explanation = (
      <>
        {coupon.percent_off}% off for the first {coupon.duration_in_months}{" "}
        {pluralize("month", coupon.duration_in_months)}, or the whole year if you pick the yearly plan.
      </>
    );
  }

  return (
    <Card inline w="340px" maxWidth="100%" mx="auto" mt={1} mb={3} pt={2} pb={3} px="18px">
      <P2 weight="500" align="center" color={config.colors.accent1}>
        <Icon size="18px" mr={1.5} offset="-4px" currentColor name="couponAltBold" />
        <span style={{ fontWeight: 700 }}>{coupon.name} discount applied</span>
      </P2>
      <Box
        ml="-18px"
        w="calc( 100% + 36px )"
        my={1.5}
        style={{ borderBottom: `1px dashed ${config.colors.uiBorderColor}` }}
      />
      <P2 align="center" color={config.colors.c2}>
        {explanation}
      </P2>
    </Card>
  );
};

const GradientWrapper = ({ inModal, children, maxWidth, ...otherProps }) => {
  const config = useConfig();
  const startColor = getColorFromCssVariable(config.colors.bg0);
  const endColor = tinycolor(getColorFromCssVariable(config.colors.bg0)).setAlpha(0.01).toString();
  if (inModal) {
    return (
      <CardHeader
        background="transparent"
        position="relative"
        zIndex="0"
        style={{ borderBottom: "none" }}
        overflow="hidden"
        padding={config.layout.padding}
        {...otherProps}
      >
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          zIndex="-1"
          maxHeight="600px"
          background="linear-gradient(60deg, rgb(255 235 0 / 5%) 10%, rgb(249 201 134 / 8%) 50%, rgb(255 51 98 / 5%) 80%)"
        />
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          zIndex="0"
          maxHeight="600px"
          background={`linear-gradient(0deg, ${startColor} 0%, ${endColor} 70%)`}
        />
        <Box position="relative" zIndex="2">
          <Stack vertical align="center" mt={7} mb={-4} gap={6} noWrap w={maxWidth} maxW="100%" mx="auto" breakAt={768}>
            {children}
          </Stack>
        </Box>
      </CardHeader>
    );
  } else {
    return (
      <Box {...otherProps}>
        <Stack vertical align="center" mt={2} gap={6} noWrap w={maxWidth} maxW="100%" mx="auto" breakAt={768}>
          {children}
        </Stack>
      </Box>
    );
  }
};

const TrialProgress = ({ ...otherProps }) => {
  const config = useConfig();

  const { user } = usePaywallState();

  const { days_left: daysLeft, duration: trialDuration } = user?.monetization?.data || {};

  const progress = clamp(1 - (trialDuration - daysLeft) / trialDuration, 0, 0.97);

  return (
    <SubtleCard
      display="inline-block"
      background={daysLeft <= 0 ? config.colors.orange : config.colors.c3}
      px={4}
      pt={2}
      pb={3}
      w="auto"
      maxWidth="100%"
      mx="auto"
      {...otherProps}
    >
      <Stack vertical align="center" gap={2} breakAt={768}>
        {daysLeft > 0 && (
          <P2 color={config.colors.c2}>
            <strong>
              {daysLeft} {pluralize("day", daysLeft)}
            </strong>{" "}
            left in free trial
          </P2>
        )}
        {daysLeft <= 0 && (
          <P2 color={config.colors.orange}>
            <strong>Your trial is over</strong>
          </P2>
        )}
        {progress > 0 && <ProgressBar width={"200px"} progress={progress} />}
      </Stack>
    </SubtleCard>
  );
};

const SectionHeader = ({ title, ...otherProps }) => {
  const hit = useBreakpoint();
  return <TitleDivider mt={hit ? 8 : 12} mb={hit ? 7 : 9} title={title} {...otherProps} />;
};

const AboutUs = (props) => {
  return (
    <Box w="100%" {...props}>
      <SectionHeader title={"Crafted by a passionate indie team"} />
      <P1 align="center" w="660px" maxW="100%" mx="auto" mt={-2} mb={6}>
        We strive to make Mailbrew better each and every day, on a mission to help people unplug from feeds and reclaim
        their lives.
      </P1>
      <MailbrewTeam />
    </Box>
  );
};

export const MailbrewTeam = () => {
  const breakpointHit = useBreakpoint(980);
  return (
    <Grid w="100%" gap={breakpointHit ? 2 : 3} columns="1fr 1fr 1fr" breakAt="768px">
      <TeamUserCard
        avatarImage={FabrizioRinaldiImage}
        name="Fabrizio Rinaldi"
        description="Co-founder, designer"
        twitterHandle="linuz90"
      />

      <TeamUserCard
        avatarImage={FrancescoDiLorenzoImage}
        name="Francesco Di Lorenzo"
        description="Co-founder, developer"
        twitterHandle="frankdilo"
      />

      <TeamUserCard
        avatarImage={AlbertoGiuntaImage}
        name="Alberto Giunta"
        description="Product engineer"
        twitterHandle="albigiu"
      />
    </Grid>
  );
};

const Testimonials = (props) => {
  const breakpointHit = useBreakpoint(980);
  const config = useConfig();

  return (
    <Box w="100%" {...props}>
      <SectionHeader title="Join our awesome users ❤️" />
      <Grid mx="auto" w="100%" gap={breakpointHit ? 2 : 3} columns="1fr 1fr" breakAt="768px">
        <UserCard
          avatarImage={DHHImage}
          name="David Heinemeier Hansson"
          description="Basecamp & Hey Co-Founder"
          link="https://world.hey.com/dhh/not-just-what-you-read-but-how-64648303"
          body={
            <P2 color={config.colors.c2} size="15px">
              The days literally seem longer. <br />
              <br />
              There's both <Highlight>more time to do the things I want to do</Highlight>, but also no attention spent
              on "everything from everyone all the time". <br />
              <br />
              Mailbrew has been a complete revelation.
            </P2>
          }
        />
        <UserCard
          avatarImage={JanelImage}
          name="Janel"
          description="BrainPint, Newsletter OS"
          body={
            <P2 color={config.colors.c2} size="15px">
              Mailbrew is a massive time saver & game changer for my curation workflow. <br />
              <br />I love <Highlight>keeping tabs on multiple topics</Highlight> and Mailbrew makes sure that I don't
              miss anything important by delivering everything to my inbox.
            </P2>
          }
        />
        <UserCard
          avatarImage={ViticciImage}
          name="Federico Viticci"
          description="Founder, MacStories"
          body={
            <P2 color={config.colors.c2} size="15px">
              I get <Highlight>all my newsletters delivered to Mailbrew</Highlight>, together with top links from
              Twitter.
              <br />
              <br />I have come to expect my daily brew at the end of the day.
            </P2>
          }
        />
        <UserCard
          avatarImage={BrianLovinImage}
          name="Brian Lovin"
          description="Product Designer, GitHub"
          body={
            <P2 color={config.colors.c2} size="15px">
              I was tired of Twitter FOMO. <br />
              <br />
              Now I get <Highlight>one email per day</Highlight> with stuff I should know about. Top links, and the best
              tweets from people I follow.
            </P2>
          }
        />
        {/* <UserCard
          avatarImage={DanielVassalloImage}
          name="Daniel Vassallo"
          twitterLink="dvassallo"
          description="The Good Parts of AWS author"
          body={`Mailbrew is so simple and yet so useful if you’re on Twitter.
            
            There are some accounts I like to read everything they put out, and Mailbrew puts this digest in my inbox every morning.`}
        /> */}
      </Grid>
    </Box>
  );
};

const UserCard = ({ avatarImage, name, description, body, link }) => {
  const config = useConfig();
  return (
    <Card inline>
      {link && (
        <ExternalLink href={link} style={{ position: "absolute", right: 10 }}>
          <Icon name="open" color={config.colors.c3} />
        </ExternalLink>
      )}
      <Stack gap={2} noWrap overflow="hidden" align="spaced" maxWidth="100%">
        <Stack vertical>
          <Stack target="_blank" noWrap overflow="hidden" maxWidth="100%">
            <img src={avatarImage} alt={name} style={{ width: "40px", height: "40px", borderRadius: "40px" }} />
            <Stack vertical gap={0.5}>
              <P1 size="16px" letterSpacing="-0.1px" noWrap overflow="hidden" maxW="100%" weight="600" lineHeight="1.3">
                {name}
              </P1>
              <P2 size="13px" noWrap overflow="hidden" maxW="100%" lineHeight="1.3">
                {description}
              </P2>
            </Stack>
          </Stack>
          {typeof body === "string" && (
            <P2 color={config.colors.c2} size="15px" style={{ whiteSpace: "pre-line" }}>
              {body}
            </P2>
          )}
          {typeof body === "object" && body}
        </Stack>
      </Stack>
    </Card>
  );
};

const TeamUserCard = ({ avatarImage, name, description, body, twitterHandle }) => {
  const config = useConfig();
  return (
    <Card inline style={{ cursor: "pointer" }} onClick={() => window.open(`https://twitter.com/${twitterHandle}`)}>
      <Stack gap={2} noWrap overflow="hidden" align="spaced" maxWidth="100%">
        <Stack vertical>
          <Stack target="_blank" noWrap overflow="hidden" maxWidth="100%">
            <img src={avatarImage} alt={name} style={{ width: "40px", height: "40px", borderRadius: "40px" }} />
            <Stack vertical gap={0.5}>
              <P1 size="16px" letterSpacing="-0.1px" noWrap overflow="hidden" maxW="100%" weight="600" lineHeight="1.3">
                {name}
              </P1>
              <P2 size="13px" noWrap overflow="hidden" maxW="100%" lineHeight="1.3">
                {description}
              </P2>
            </Stack>
          </Stack>
          {typeof body === "string" && (
            <P2 color={config.colors.c2} size="15px" style={{ whiteSpace: "pre-line" }}>
              {body}
            </P2>
          )}
          {typeof body === "object" && body}
        </Stack>
        {/* {twitterHandle && (
          <Button
            noStretch
            color="#1DA1F2"
            variant={["secondary", "small"]}
            icon="twitter"
            onClick={() => window.open(`https://twitter.com/${twitterHandle}`)}
          >
            Follow
          </Button>
        )} */}
      </Stack>
    </Card>
  );
};
