const tweetRegex = /^https?:\/\/twitter\.com\/(?:#!\/)?(\w+)\/status(es)?\/(\d+)/;

export function isTweetURL(url) {
  if (!url) return [false, null];
  const match = url.match(tweetRegex);
  if (!match) return [false, null];
  const tweetID = match[3];
  return [true, tweetID];
}

export function isVideoURL(url) {
  return url.includes("youtube.com") || url.includes("vimeo.com");
}
