import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";

/**
 * Splits the provided objects in Today / Yesterday / This Week / Older.
 * Uses the dateKey to get the date for each object and perform the comparison.
 *
 * Example output: [{ label: "Today", objects: [...]]
 */
export default function recencySplitter(objects, dateKey) {
  const res = {};

  for (const obj of objects) {
    const date = dayjs(obj[dateKey]);

    if (date.isToday()) {
      if (res.today) res.today.push(obj);
      else res.today = [obj];
    } else if (date.isYesterday()) {
      if (res.yesterday) res.yesterday.push(obj);
      else res.yesterday = [obj];
    } else if (date.isPastSevenDays()) {
      if (res.past_seven_days) res.past_seven_days.push(obj);
      else res.past_seven_days = [obj];
    } else {
      if (res.older) res.older.push(obj);
      else res.older = [obj];
    }
  }

  // transform res from an obj to an array in the final form
  const finalRes = [];

  if (res.today) finalRes.push({ label: "Today", items: res.today });
  if (res.yesterday) finalRes.push({ label: "Yesterday", items: res.yesterday });
  if (res.past_seven_days) finalRes.push({ label: "This Week", items: res.past_seven_days });
  if (res.older) finalRes.push({ label: "Older", items: res.older });

  return finalRes;
}

const isPastSevenDays = (option, dayjsClass, dayjsFactory) => {
  // add dayjs().isPastSevenDays()
  dayjsClass.prototype.isPastSevenDays = function () {
    const now = dayjsFactory();
    const sevenDaysAgo = now.subtract(7, "day");
    // the "day" parameter makes the comparison with day granularity
    return this.isBefore(now, "day") && this.isAfter(sevenDaysAgo, "day");
  };
};

dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(isPastSevenDays);
