/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Box, Expandable, HStack, Icon, P2, SmallText, Spacer, Stack, useConfig } from "@mailbrew/uikit";
import { motion } from "framer-motion";
import { Fragment, useState } from "react";

const SourceEditorSection = ({
  title,
  subtitle,
  collapsable,
  defaultOpen = false,
  headerExtras,
  icon,
  noPadding,
  noLateralPadding,
  noChildrenLateralPadding,
  noBorderTop,
  noBorderBottom,
  noBorders,
  style,
  children,
  ...otherProps
}) => {
  const [sectionOpen, setSectionOpen] = useState(defaultOpen);

  const config = useConfig();

  const handleCollapseClick = () => {
    if (collapsable) {
      setSectionOpen(!sectionOpen);
    }
  };

  const showTitle = title || headerExtras || collapsable;

  return (
    <Box
      w="100%"
      bt={!noBorderTop && !noBorders && `1px solid ${config.colors.c6}`}
      bb={!noBorderBottom && !noBorders && `1px solid ${config.colors.c6}`}
      mb="-1px"
      style={style}
      pt="8px"
      pb="10px"
      {...otherProps}
    >
      {showTitle && (
        <HStack
          px={noLateralPadding ? 0 : config.sidebar.padding.lateral}
          align="spaced"
          noWrap
          mb={collapsable ? 0 : 0.5}
          gap={0}
        >
          {title && (
            <P2
              color={config.colors.c2}
              weight="500"
              onClick={handleCollapseClick}
              style={{ cursor: collapsable ? "pointer" : "auto" }}
              noWrap
            >
              {icon && <Icon name={icon} size="13px" currentColor mr={1.5} offset="-1px" />}
              {title}
            </P2>
          )}
          <HStack align="right" vAlign="top" gap={1.5}>
            {headerExtras && <Fragment>{headerExtras}</Fragment>}
            {collapsable && (
              <Stack
                bg={config.colors.c6}
                radius="20px"
                padding="4px 8px"
                onClick={handleCollapseClick}
                noWrap
                gap={1}
                lineHeight="0"
                style={{ cursor: "pointer" }}
              >
                <SmallText lineHeight="0">{sectionOpen ? "Hide" : "Show"}</SmallText>
                <Box
                  flex
                  ai="center"
                  jc="center"
                  as={motion.div}
                  initial={{ rotate: 0 }}
                  animate={sectionOpen ? { rotate: 90 } : { rotate: -90 }}
                >
                  <Icon size={16} name="chevronLeft" color={config.colors.c2} />
                </Box>
              </Stack>
            )}
          </HStack>
        </HStack>
      )}
      <SectionContent
        px={noLateralPadding || noChildrenLateralPadding ? 0 : config.sidebar.padding.lateral}
        collapsable={collapsable}
        sectionOpen={sectionOpen}
        setSectionOpen={setSectionOpen}
      >
        {subtitle && <P2 mb={2}>{subtitle}</P2>}
        {children && (
          <Fragment>
            {!noPadding && (showTitle || subtitle) && <Spacer size={collapsable ? 0 : 1} />}
            {children}
          </Fragment>
        )}
      </SectionContent>
    </Box>
  );
};

const SectionContent = ({ px, collapsable, children, sectionOpen, setSectionOpen }) => {
  if (collapsable) {
    return (
      <Box px={px}>
        <Expandable expanded={sectionOpen}>
          <Spacer size={2} />
          {children}
        </Expandable>
      </Box>
    );
  } else {
    return <Box px={px}>{children}</Box>;
  }
};

export default SourceEditorSection;
