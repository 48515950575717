export default function useSWRInfiniteHelper({ data, error, size, setSize, isValidating }) {
  const isLoadingInitialData = !data && !error;
  const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === "undefined");
  const canLoadMore = !isLoadingMore && size > 0 && data && !!data[size - 1]?.next;
  const isEmpty = data?.[0]?.results?.length === 0;

  function loadMore() {
    setSize(size + 1);
  }

  return {
    isEmpty,
    isLoadingInitialData,
    isLoadingMore,
    canLoadMore,
    loadMore,
  };
}
