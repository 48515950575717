import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { useState } from "react";
import { stripeKey } from "utils/env";

const StripeProvider = ({ children }) => {
  // avoid creating a stripe object on every render, but only load the stripe object
  // when needed instead than on every page
  const [stripePromise] = useState(() => loadStripe(stripeKey));
  return <Elements stripe={stripePromise}>{children}</Elements>;
};

export default StripeProvider;
