/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Button, notif } from "@mailbrew/uikit";
import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

const CopyButton = ({ copyText, onCopy, label = "Copy", icon, children, ...otherProps }) => {
  return (
    <CopyToClipboard
      text={copyText}
      onCopy={() => {
        onCopy && onCopy();
        notif.success("Copied");
      }}
    >
      <Button noStretch icon={icon} {...otherProps}>
        {children ?? label}
      </Button>
    </CopyToClipboard>
  );
};

export const UnstyledCopyButton = (props) => {
  const { copyText, copyLabel = "copy", onCopy, style, ...otherProps } = props;

  const [copied, setCopied] = useState(false);

  return (
    <CopyToClipboard
      text={copyText}
      onCopy={() => {
        onCopy && onCopy();
        setCopied(true);
        notif.success("Copied");
      }}
    >
      <span style={{ cursor: "pointer", ...style }} {...otherProps}>
        {copied ? "copied" : copyLabel}
      </span>
    </CopyToClipboard>
  );
};

export default CopyButton;
