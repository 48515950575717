/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import {
  Box,
  Container,
  Icon,
  P1,
  PWARoundedCorners,
  SafeAreaInsetTop,
  Stack,
  TopLoader,
  useBreakpoint,
  useConfig,
  useDarkMode,
  useResetConfig,
  useUpdateConfig,
} from "@mailbrew/uikit";
import { motion } from "framer-motion";
import useIsAtTopOrBottomOfScroll from "hooks/useIsAtTopOrBottomOfScroll";
import useKeyPressed from "hooks/useKeyPressed";
import useUpdateUser from "hooks/useUpdateUser";
import Head from "next/head";
import Link from "next/link";
import { useRouter } from "next/router";
import { SaveLinkModal } from "pages/saved";
import { Fragment, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import signUpLink from "utils/signUpLink";
import zIndexes from "zIndexes";
import { darkConfig } from "../config";
import urls from "../urls";
import { MailbrewLogo } from "./brand/MailbrewLogo";
import MonetizationStatusBanner from "./MonetizationStatusBanner";
import PaywallModal from "./PaywallModal";
import { usePaywallState } from "./PaywallStateProvider";
import StyledA from "./StyledA";
import StyledLink from "./StyledLink";
import UserMenu from "./UserMenu";

const Page = ({
  title,
  fullTitle,
  background,
  stickyHeader = false,
  branded,
  showMonetizationBanner,
  showTimedOffer,
  noNavigation = false,
  noLogin,
  loading,
  fixed,
  signupButtonRef,
  headerComponents,
  style,
  children,
}) => {
  const { debuggingUI } = usePaywallState();

  useUpdateUser();

  return (
    <Container fixed={fixed} background={background} style={style}>
      {debuggingUI}
      <PageUi
        title={title}
        fullTitle={fullTitle}
        stickyHeader={stickyHeader}
        headerComponents={headerComponents}
        branded={branded}
        showMonetizationBanner={showMonetizationBanner}
        showTimedOffer={showTimedOffer}
        noNavigation={noNavigation}
        loading={loading}
        noLogin={noLogin}
        signupButtonRef={signupButtonRef}
      >
        {children}
      </PageUi>
    </Container>
  );
};

const PageUi = (props) => {
  const {
    title,
    fullTitle,
    stickyHeader,
    headerComponents,
    branded,
    showMonetizationBanner,
    noNavigation = false,
    loading,
    noLogin,
    children,
    signupButtonRef,
  } = props;

  /* -------------------------------- Dark Mode ------------------------------- */

  const config = useConfig();
  const updateConfig = useUpdateConfig();
  const resetConfig = useResetConfig();
  const [darkMode] = useDarkMode();

  useEffect(() => {
    if (darkMode) {
      updateConfig(darkConfig);
    } else {
      resetConfig();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [darkMode]);

  /* --------------------------- User & Monetization -------------------------- */

  const { paywallModalShown, setPaywallModalShown, user } = usePaywallState();

  /* ---------------------------------- Crisp --------------------------------- */

  const LoggedOutMailbrewLogo = () => (
    <a style={{ lineHeight: 0 }} href="https://mailbrew.com/">
      <MailbrewLogo responsive />
    </a>
  );

  /* -------------------------------- Elements -------------------------------- */

  const userOrSignup = (
    <Fragment>
      {user ? (
        <UserMenu zIndex={zIndexes.userMenu} />
      ) : noLogin ? null : (
        <Stack noWrap>
          <StyledLink to={urls.login()} variant={["line", "small"]}>
            Login
          </StyledLink>
          <StyledA href={signUpLink(signupButtonRef)} variant={"small"}>
            Sign Up
          </StyledA>
        </Stack>
      )}
    </Fragment>
  );

  /* ---------------------------------- State --------------------------------- */

  const [savedLinkModalShown, setSavedLinkModalShown] = useState(false);

  useKeyPressed(
    "S",
    () => {
      setSavedLinkModalShown(true);
    },
    {
      withShift: true,
    }
  );

  const showHeader = !noNavigation;

  return (
    <Fragment>
      {user && <SaveLinkModal show={savedLinkModalShown} setShow={setSavedLinkModalShown} />}
      <PaywallModal show={paywallModalShown} setShow={setPaywallModalShown} />
      {branded && <MailbrewBrand urlRef={signupButtonRef} />}
      <TopLoader loading={loading} />
      <PWARoundedCorners color="black" radius={18} />
      <Head>
        <title>{fullTitle ?? title ?? "Mailbrew"}</title>
      </Head>
      {!showHeader && (
        <Box w="100%">
          <SafeAreaInsetTop />
        </Box>
      )}
      {showMonetizationBanner && <MonetizationStatusBanner />}
      {showHeader && (
        <Fragment>
          <StickyWrapper sticky={stickyHeader}>
            <Header>
              <Stack noWrap gap={3} align="spaced" w="100%" vAlign="center">
                {user ? <MainNavigation /> : <LoggedOutMailbrewLogo />}
                {userOrSignup}
              </Stack>
            </Header>
            <Box
              mx="auto"
              overflow="hidden"
              maxW={config.layout.width}
              noWrap
              w="100%"
              pt={2}
              px={config.layout.padding}
            >
              {headerComponents}
            </Box>
          </StickyWrapper>
        </Fragment>
      )}
      {children}
    </Fragment>
  );
};

const Header = ({ children }) => {
  const config = useConfig();
  const hit = useBreakpoint(HEADER_MOBILE_BREAKPOINT);

  const style = {
    width: "100%",
    boxSizing: "border-box",
    paddingLeft: config.layout.padding,
    paddingRight: config.layout.padding,
    background: config.colors.bg1,
    borderBottom: `1px solid ${config.colors.uiBorderColor}`,
    paddingTop: hit ? 8 : 6,
    paddingBottom: hit ? 8 : 6,
    display: "flex",
  };

  return (
    <div style={style}>
      <Stack gap={3} noWrap w="100%" overflow="hidden" vAlign="center">
        {children}
      </Stack>
    </div>
  );
};

const StickyWrapper = ({ sticky, children, ...props }) => {
  if (sticky) {
    return <StickyWrapperInner {...props}>{children}</StickyWrapperInner>;
  } else {
    return children;
  }
};

const StickyWrapperInner = ({ style, children }) => {
  const ref = useRef();
  const config = useConfig();
  const [darkMode] = useDarkMode();

  const [headerHeight, setHeaderHeight] = useState(null);

  useEffect(() => {
    if (ref.current) {
      setHeaderHeight(ref.current.getBoundingClientRect().height);
    }
  }, [children]);

  const [headerVisible, , scrollTop] = useIsAtTopOrBottomOfScroll({
    offset: 100,
  });

  const [bannerHeight, setBannerHeight] = useState(0);
  const { monetizationStatus } = usePaywallState();

  useEffect(() => {
    const banner = document.getElementById("monetization-banner");
    setBannerHeight(banner ? banner.offsetHeight : 0);
  }, [monetizationStatus]);

  // Fix SSR build issue (document is not defined)
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <Fragment>
      <div style={{ height: headerHeight }} />
      {show &&
        ReactDOM.createPortal(
          <motion.div
            ref={ref}
            style={{
              position: "fixed",
              top: 0 + bannerHeight,
              width: "100%",
              boxSizing: "border-box",
              zIndex: zIndexes.stickyHeader,
              ...style,
            }}
            initial={false}
            animate={{
              top: headerVisible ? 0 + Math.max(bannerHeight - Math.max(scrollTop, 0), 0) : "-" + headerHeight + "px",
              boxShadow: scrollTop > 0 && headerVisible ? "0px 2px 30px rgba(0,0,0,0.1)" : "0px 0px 0px rgba(0,0,0,0)",
              borderBottom:
                darkMode && scrollTop > 0 && headerVisible
                  ? `1px solid rgba(255,255,255,0.06)`
                  : `1px solid rgba(255,255,255,0)`,
              background: config.colors.bg0,
              paddingBottom: scrollTop > 0 ? 6 : 0,
            }}
            transition={{
              type: "spring",
              duration: 0.6,
              bounce: 0.1,
            }}
          >
            {children}
          </motion.div>,
          document.body
        )}
    </Fragment>
  );
};

const MainNavigation = () => {
  return (
    <Stack ml={10} align="center" mr={1} gap={1} noWrap w="100%">
      <NavButton to={urls.brews()} icon="mailbrew" label="Brews" />
      <NavButton to={urls.inbox()} icon="inbox" label="Newsletters" />
      <NavButton to={urls.saved()} icon="later" label="Saved" />
    </Stack>
  );
};

export default Page;

const MailbrewBrand = ({ urlRef = "brew-public-page" }) => {
  const config = useConfig();

  const lineCSS = css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: ${config.colors.accent1};
  `;

  const wrapperCSS = css`
    display: block;
    text-decoration: none;
    position: absolute;
    top: 4px;
    right: 0;
    background: ${config.colors.accent1};
    padding: 0 4px;
    border-radius: 0 0 0 6px;
    z-index: 6;
    transform: scale(1.001);
    transform-origin: 100% 0;
    transition: transform 0.2s cubic-bezier(0.2, 0, 0.3, 1);
    :hover {
      transform: scale(1.15);
    }
    :before {
      content: "";
      display: block;
      width: 7px;
      height: 7px;
      background: radial-gradient(circle at 0 100%, rgba(0, 0, 0, 0) 7px, ${config.colors.accent1} 7px);
      position: absolute;
      left: -7px;
      top: 0px;
    }
  `;

  return (
    <Fragment>
      <div css={lineCSS} />
      <a href={signUpLink(urlRef)} css={wrapperCSS}>
        <MailbrewLogo color="white" label={false} size="17px" />
      </a>
    </Fragment>
  );
};

const StyledNavLink = ({ href, style, activeStyle, type = "link", onClick, children, ...rest }) => {
  const router = useRouter();
  const isActive = href === router.pathname;
  const [isHovering, setIsHovering] = useState(false);

  return (
    <Link href={href} onClick={onClick} {...rest} passHref>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        style={{
          textDecoration: "none",
          borderBottom: "none",
          ...style,
          ...(isActive || isHovering ? activeStyle : {}),
        }}
      >
        {children}
      </a>
    </Link>
  );
};

const NavButton = ({ onClick, to, exact, icon, label, style, forceActive, ...rest }) => {
  const config = useConfig();
  const hit = useBreakpoint(HEADER_MOBILE_BREAKPOINT);

  return (
    <StyledNavLink
      key={label}
      style={{
        boxSizing: "border-box",
        color: !forceActive ? config.colors.c3 : config.colors.c1,
        padding: hit ? "4px 9px" : "3px 6px",
      }}
      activeStyle={{ color: config.colors.c1, background: config.colors.c6, borderRadius: "6px" }}
      href={to}
      exact={exact}
    >
      <Stack noWrap gap={0} w="100%">
        <Icon name={icon} size={hit ? 24 : 18} currentColor onClick={onClick} style={{ cursor: "pointer", ...style }} />
        {!hit && (
          <P1 style={{ color: "currentcolor" }} ml={2}>
            {label}
          </P1>
        )}
      </Stack>
    </StyledNavLink>
  );
};

export const HEADER_MOBILE_BREAKPOINT = 500;
