import { Button } from "@mailbrew/uikit";
import Link from "next/link";
import UnstyledA from "./UnstyledA";

const StyledLink = ({ to, track, variant, onClick, linkStyle, ...rest }) => (
  <Link href={to} onClick={onClick}>
    <UnstyledA>
      <Button as="div" variant={variant} style={{ flex: 1, display: "inline-flex", ...linkStyle }} {...rest} />
    </UnstyledA>
  </Link>
);

export default StyledLink;
