/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { HStack, useConfig } from "@mailbrew/uikit";
import MailbrewLogoSvg from "../../images/brand/mailbrew_logo.svg";

export const MailbrewLogo = ({
  responsive,
  label = true,
  icon = true,
  size = "22px",
  style,
  CSS: externalCSS,
  wrapperStyle,
  onClick,
  ...otherProps
}) => {
  const config = useConfig();

  const { breakPoint } = config.responsive;
  const { accent1 } = config.colors;
  const { color = accent1, component: Component = "div" } = otherProps;

  // This makes an "em" or "rem" size become a nicely rounded pixel size for the SVG.
  const logoSize = (() => {
    if (size.toString().includes("em")) {
      return Math.round((parseFloat(size) * 16) / 2) * 2 + 3 + "px";
    } else {
      return parseFloat(size) + 3 + "px";
    }
  })();

  const svgCSS = css`
    path {
      fill: ${color};
    }
    width: ${logoSize};
    height: ${logoSize};
    flex: 1 0 auto;
  `;

  const logotypeCSS = css`
    ${onClick ? "cursor: pointer;" : ""}
    font-weight: 500;
    line-height: 1;
    color: ${color};
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    span {
      font-family: "new-spirit", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      position: relative;
      bottom: ${icon ? "1px" : ""};
      text-decoration: none;
      border: none;
      font-size: ${size};
    }
    ${responsive
      ? css`
          @media (max-width: ${parseInt(breakPoint) + "px"}) {
            span {
              display: none;
            }
          }
        `
      : ""}
  `;

  return (
    <Component
      onClick={onClick}
      style={wrapperStyle}
      href={otherProps.href}
      target={otherProps.target}
      rel={otherProps.rel}
      css={[logotypeCSS, externalCSS]}
    >
      <HStack vAlign="center" gap="0.1em" noWrap>
        {icon && <MailbrewLogoSvg css={svgCSS} style={style} />}
        {label && <span>mailbrew</span>}
      </HStack>
    </Component>
  );
};
