import { Box, H2, HStack, P1, P2, Stack, useBreakpoint, useConfig } from "@mailbrew/uikit";
import ShareSelectionOverlay from "components/ShareSelectionOverlay";
import StyledA from "components/StyledA";
import { axiosSwrFetcher } from "dependencies/api";
import { AnimatePresence, motion } from "framer-motion";
import FullScreenModal from "FullScreenModal";
import useIsAtTopOrBottomOfScroll from "hooks/useIsAtTopOrBottomOfScroll";
import useKeyPressed from "hooks/useKeyPressed";
import Head from "next/head";
import { Fragment, useEffect, useRef, useState } from "react";
import useSWR from "swr";
import { formatDate } from "utils/formatDate";
import plausible from "utils/plausible";
import { pluralize } from "utils/pluralize";
import trimText from "utils/trimText";
import zIndexes from "zIndexes";
import ExternalLink from "./ExternalLink";
import LoadingLogo from "./LoadingLogo";
import ReaderModeContentDiv from "./ReaderModeContentDiv";
import {
  ModalToolbar,
  ToolbarArchiveButton,
  ToolbarCloseButton,
  ToolbarReadLaterButton,
  ToolbarShareButton,
  ToolbarTweetButton,
} from "./Toolbars";

const showDebugArticleData = false;

export const ReaderViewModal = ({ url, show, setShow, hideReadLater, withArchive, onArchiveClick, source }) => {
  const config = useConfig();

  const [toolbarShown, setToolbarShown] = useState(show);

  // const extractEndpointURL = url && `http://localhost:3001/v1/extract?url=${url}`;
  const extractEndpointURL = url && `https://article-extractor.mailbrew.com/extract?url=${url}`;

  const { data: article, error } = useSWR(extractEndpointURL, {
    fetcher: axiosSwrFetcher,
    onSuccess: () => {
      plausible.track("Open Reader Mode", { url, source });
    },
  });

  useKeyPressed("Escape", () => setShow(false));

  const handleSetShow = (show) => {
    // Reset toolbar state when closing
    if (!show) {
      // Toolbar need to be hidden before the entire modal is closed, otherwise its exit animation breaks
      setToolbarShown(false);
    }

    // Then close
    setTimeout(() => setShow(show));
  };

  // Show the toolbar when showing the modal
  useEffect(() => {
    if (show) {
      setToolbarShown(show);
    }
  }, [show]);

  // Extract article parts

  // Compute image size
  const articleImageURL = makeUrlHttps(article?.metadata?.image);
  const { imageSize: articleImageSize, imageProcessed: isImageProcessed } = useImageSize(articleImageURL);
  const showMainImage = articleImageURL && articleImageSize.width > 600;

  // Show article in Reader Mode after all fetching and processing is done
  // This way there are no glitches due to loading
  const isArticleLoaded = article && isImageProcessed;

  const containerRef = useRef();

  const modalRef = useRef(null);
  const [toolbarVisible, setToolbarVisible] = useIsAtTopOrBottomOfScroll({
    scrollElement: modalRef.current,
    offset: 250,
  });

  useEffect(() => {
    setToolbarVisible(show);
  }, [setToolbarVisible, show]);

  const uiState = (() => {
    if (error) return "error";
    if (!article) return "loading";
    return "loaded";
  })();

  return (
    <FullScreenModal show={show} setShow={handleSetShow} zIndex={zIndexes.readerViewModal} ref={containerRef}>
      {uiState === "error" && (
        <Box style={{ flex: 1 }} flex fd="column" ai="center" jc="center">
          <P1 mb={3}>Sorry, we couldn't fetch the article 😔</P1>
          <StyledA icon="open" variant="white" href={url} targetBlank>
            Open in Browser
          </StyledA>
        </Box>
      )}

      {uiState === "loading" && <LoadingElement show={!isArticleLoaded} />}

      {uiState === "loaded" && (
        <Fragment>
          <Head>
            <title>{article.title}</title>
          </Head>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              opacity: isArticleLoaded ? 1 : 0,
              transition: isArticleLoaded ? "0.2s 0.2s" : "none",
              maxWidth: "100%",
            }}
          >
            {showMainImage ? (
              <CoverImage url={articleImageURL}>
                <TitleAndAttributes article={article} url={url} withBgImage />
              </CoverImage>
            ) : (
              <Box mt={8} mb={4} maxW="660px" mx="auto" px={config.layout.padding} overflow="hidden">
                <TitleAndAttributes article={article} url={url} />
              </Box>
            )}
            {showDebugArticleData && (
              <pre style={{ width: 500, overflow: "scroll" }}>{JSON.stringify(article, null, 2)}</pre>
            )}
            <Stack
              vertical
              vAlign="start"
              w="660px"
              maxW="100%"
              mx="auto"
              px={config.layout.padding}
              style={{ flex: 1 }}
            >
              <Box w="100%">
                <ShareSelectionOverlay key={show} url={url}>
                  <ReaderModeContentDiv contentHTML={article.content} />
                </ShareSelectionOverlay>
              </Box>
              <ModalToolbar width={280} mt={8} show={toolbarVisible && toolbarShown && isArticleLoaded}>
                <ToolbarShareButton url={url} />
                <ToolbarTweetButton url={url} title={`${article.title} (via @mailbrew)`} />
                {!hideReadLater && <ToolbarReadLaterButton url={url} />}
                {withArchive && onArchiveClick && (
                  <ToolbarArchiveButton
                    onClick={() => {
                      onArchiveClick();
                      setShow(false);
                    }}
                  />
                )}
                <ToolbarCloseButton onClick={() => handleSetShow(false)} />
              </ModalToolbar>
            </Stack>
          </div>
        </Fragment>
      )}
    </FullScreenModal>
  );
};

const TitleAndAttributes = ({ article, url, withBgImage }) => {
  const isMobile = useBreakpoint();

  return (
    <>
      <H2
        as="a"
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        weight="500"
        color={withBgImage ? "white" : undefined}
      >
        {trimText(article.title, 300)}
      </H2>
      <HStack mt={2} gap={1.5}>
        <Attribute light={withBgImage} iconURL={article.metadata.logo} text={article.metadata.publisher} url={url} />

        {article.length && <AttrSeparator />}
        <AttrReadingTime light={withBgImage} characters={article.length} />

        {article.metadata.date && <AttrSeparator />}
        <AttrDate light={withBgImage} dateStr={article.metadata.date} />

        {!isMobile && (
          <HStack gap={1.5} noWrap>
            {article.metadata.author && <AttrSeparator />}
            <Attribute light={withBgImage} text={article.metadata.author} />
          </HStack>
        )}
      </HStack>
    </>
  );
};
const AttrP = ({ children, light, ...props }) => {
  return (
    <P2 color={(c) => (light ? c.colors.c5 : c.colors.c3)} {...props}>
      {children}
    </P2>
  );
};

const AttrSeparator = ({ light }) => <AttrP light={light}>•</AttrP>;

const AttrReadingTime = ({ characters, light }) => {
  const wpm = 300; // readable words per minute
  const word_length = 6; // standardized number of chars in calculable word
  const words = characters / word_length;
  const readingTime = Math.max(1, parseInt(Math.round(words / wpm)));

  const isMobile = useBreakpoint();

  if (!characters) return null;
  if (isNaN(readingTime)) return null;

  const minSuffix = isMobile ? "m" : " " + pluralize("minute", readingTime) + " read";

  return (
    <AttrP light={light}>
      {readingTime}
      {minSuffix}
    </AttrP>
  );
};

const AttrDate = ({ dateStr, light }) => {
  if (!dateStr) return null;
  return <AttrP light={light}>{formatDate(dateStr)}</AttrP>;
};

const Attribute = ({ text, iconURL, url, light }) => {
  if (!text) return null;

  return (
    <HStack>
      {iconURL && <img src={iconURL} alt="site icon" style={{ width: 20, borderRadius: 4 }} />}
      <ExternalLink href={url}>
        <AttrP light={light} noWrap>
          {text}
        </AttrP>
      </ExternalLink>
    </HStack>
  );
};

function makeUrlHttps(url) {
  if (url && typeof url === "string") {
    return url.replace("http://", "https://");
  } else {
    return url;
  }
}

const LoadingElement = ({ show }) => {
  const config = useConfig();
  return (
    <AnimatePresence>
      {show && (
        <Box
          as={motion.div}
          initial={false}
          animate={show ? { scale: 1, opacity: 1 } : { scale: 0.85, opacity: 0 }}
          transition={{ type: "spring", duration: 0.3, bounce: 0.3 }}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            height: "100vh",
            flex: 1,
          }}
          flex
          fd="column"
          ai="center"
          jc="center"
        >
          {/* <Spinner /> */}
          <LoadingLogo />
          <P2 mt={3} color={config.colors.c4}>
            Loading your article...
          </P2>
        </Box>
      )}
    </AnimatePresence>
  );
};

const CoverImage = ({ url, children }) => {
  const config = useConfig();
  return (
    <Box
      style={{
        background: `black url(${url})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        flex: "0 0 auto",
        overflow: "hidden",
      }}
      mb={6}
      position="relative"
      flex
      fd="column"
      ai="flex-start"
      jc="flex-end"
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        bottom={0}
        right={0}
        bg="linear-gradient(rgba(0,0,0,.3) 0%, rgba(0,0,0,.4) 40%, rgba(0,0,0,.8) 90%)"
      />
      <Box padding={config.layout.padding} w="660px" maxWidth="100%" mx="auto" zIndex="1" overflow="hidden">
        <div style={{ height: "0px", paddingBottom: "40%" }} />
        {children}
      </Box>
    </Box>
  );
};

const useImageSize = (url) => {
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const [imageProcessed, setImageProcessed] = useState(false);

  useEffect(() => {
    setImageProcessed(false);
    if (url) {
      if (!url.includes("https://")) {
        setImageProcessed(true);
        return;
      }
      const urlFromString = new URL(url);
      const cleanUrl = urlFromString?.origin + urlFromString?.pathname;
      if (cleanUrl && cleanUrl.match(/\.(jpeg|jpg|gif|png)$/)) {
        let img = new Image();
        img.src = cleanUrl;
        img.onload = () => {
          setImageSize({ width: img.width, height: img.height });
          setImageProcessed(true);
        };
        let timeout = setTimeout(() => {
          setImageProcessed(true);
          return;
        }, 4000);
        return () => clearTimeout(timeout);
      } else {
        setImageProcessed(true);
      }
    } else {
      setImageProcessed(true);
    }
  }, [url]);

  return { imageSize, imageProcessed };
};
