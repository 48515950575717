export default function computeIFrameHeight(iframe, extraPadding = 40) {
  const document = iframe.contentDocument ? iframe.contentDocument : iframe.contentWindow.document;
  const body = document?.body ? document.body : document?.documentElement;

  const elements = body.querySelectorAll("*");

  // approach borrowed from https://github.com/davidjbradshaw/iframe-resizer
  // lowestElement heightCalculationMethod
  // it finds the element that's further down the page by scanning all elements

  let elVal = 0;
  let maxVal = 0;

  elements.forEach((element) => {
    elVal =
      element.getBoundingClientRect()?.bottom + parseInt(window.getComputedStyle(element, null)?.marginBottom ?? 0);
    if (elVal > maxVal) maxVal = elVal;
  });

  // fallback to body height
  if (maxVal === 0 || elements.length === 0) {
    return `${body.offsetHeight + extraPadding}px`;
  }

  return `${maxVal + extraPadding}px`;
}
