export default function createMailtoLink({ to, subject, body }) {
  let res = `mailto:${to}`;

  if (subject) {
    res += `?subject=${subject}`;
  }

  if (body) {
    res += (subject ? "&" : "?") + `body=${encodeURIComponent(body)}`;
  }

  return res;
}
