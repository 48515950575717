/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useConfig } from "@mailbrew/uikit";
import useTimeout from "hooks/useTimeout";
import { useState } from "react";
import Confetti from "react-dom-confetti";

const ConfettiEffect = ({ intensity = 1 }) => {
  const config = useConfig();
  const { accent1, accent2, accent3, c3 } = config.colors;
  const confettiConfig = makeConfettiConfig({ c3, accent1, accent2, accent3, intensity });

  const [show, setShow] = useState(false);
  useTimeout(() => {
    setShow(true);
  }, 0);

  return <Confetti active={show} config={confettiConfig} />;
};

const makeConfettiConfig = ({ c3, accent1, accent2, accent3, intensity }) => ({
  angle: "95",
  spread: 181 * intensity,
  startVelocity: 50 * intensity,
  elementCount: 50,
  dragFriction: "0.12",
  duration: "16000",
  delay: "3",
  width: 12 * intensity + "px",
  height: 12 * intensity + "px",
  colors: [accent1, accent2, accent3, c3],
});

export default ConfettiEffect;
