import { Button } from "@mailbrew/uikit";
import useBiggerTouchButtonsStyle from "hooks/useBiggerTouchButtonsStyle";

export const PillButton = ({ children, onClick, color, style, ...otherProps }) => {
  const btnStyle = useBiggerTouchButtonsStyle();

  return (
    <Button
      variant={color ? ["pill"] : ["pill", "white"]}
      color={color}
      onClick={onClick}
      maxW="100%"
      style={{
        ...btnStyle,
        ...style,
      }}
      {...otherProps}
    >
      {children}
    </Button>
  );
};
